import styled from 'styled-components'
import DoneIcon from '@mui/icons-material/Done'
import { useHistory } from "react-router-dom"

const ActivateAccount = ({user, isUserActivated}) => {

  const history = useHistory()

  const handleActivateAccount = () => {
    history.push('/settings?ref=activateAccount')
  }

  return (
    isUserActivated === false && user.email.split('@')[0] !== 'admin' && <ActivateAccountContainer>
      <div className='activateAccount__wrapper'>
        <div className='iconLabelContainer'>
          <DoneIcon className='icon'/>
          <span className='label'>Please activate your account.</span>
        </div>
        <span className='descriptionText'>
        <b>Please change your password to activate.</b>
          <br />
          <br />
          <u>Once your account is activated then you can book reservations.</u>
        </span>
        <span className='activateAccountButton' onClick={handleActivateAccount}>Activate</span>
      </div>
    </ActivateAccountContainer>
  )
}

const ActivateAccountContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: calc(100vh - 139px);

  .activateAccount__wrapper {
    display: grid;
    max-width: 600px;
    place-items: center;
    padding: 25px;
    font-weight: 600;
    background: #DA3840;
    color: #ffffff;
    border-radius: 25px;

    .iconLabelContainer {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: fit-content;
      width: 100%;
      margin-bottom: 15px;
      padding: 0 15px;
      background: var(--theme-bg);
      border-radius: 15px;
      user-select: none;

      .label {
        font-size: 1.5rem;
        color: #DA3840;
      }

      .icon {
        width: 50px;
        font-size: 4rem;
        color: #DA3840;
      }
    }

    .descriptionText {
      user-select: none;
      text-align: center;
    }

    .activateAccountButton {
      margin-top: 25px;
      text-align: center;
      width: 200px;
      padding: 10px 25px;
      font-size: 1.2rem;
      font-weight: 600;
      color: #DA3840;
      border-radius: 10px;
      background: var(--theme-bg);
      user-select: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

  }
`

export default ActivateAccount