import { useState } from 'react'
import styled from 'styled-components'
import '../../animations.css'
import '../../app.css'
import { PageContainer, OptionsContainer, NextButton } from './StyledComponents'
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';

function CheckAvailability({ data, setData, updatePages }) {

    const [flightNumber, setFlightNumber] = useState('')
    const [arrivalFlightNumber, setArrivalFlightNumber] = useState('')
    const [departureFlightNumber, setDepartureFlightNumber] = useState('')

    const updateData = (e) => {
        e.preventDefault()
        updatePages('detailsPage')
        
        if(data.service !== 'connection') {
            setData({...data, flightNumber})
        } else {
            setData({...data, flightNumber: arrivalFlightNumber, arrivalFlightNumber, departureFlightNumber})
        }
    }

    return (
        <PageContainer>
            {data.service !== 'connection' &&
                <OptionsContainer width={550} fontSize='1.2em' tabIndex='1'>
                    <FormContainer onSubmit={updateData}>
                        <FlightNumberContainer>
                            <div className="single-container">
                                <div className='title'>
                                    <span className="text">Enter Flight Number</span>
                                    <AirplaneTicketOutlinedIcon className='icon' />
                                </div>
                                <div className='flight-number-box'>
                                    <input type='text' placeholder='eg Flight Number' value={flightNumber} onChange={e => setFlightNumber(e.target.value.replace(' ', ''))} />
                                </div>
                            </div>
                        </FlightNumberContainer>
                        <NextButton className='checkButton' type='submit' onClick={updateData}>CHECK FLIGHTS</NextButton>
                    </FormContainer>
                </OptionsContainer>
            }
            {data.service === 'connection' &&
                <>
                    <OptionsContainer width={550} fontSize='1.2em' tabIndex='1'>
                        <FormContainer onSubmit={updateData}>
                            <FlightNumberContainer>
                                <div className="single-container">
                                    <div className='title'>
                                        <span className="text">Enter Flight Number</span>
                                        <AirplaneTicketOutlinedIcon className='icon' />
                                    </div>
                                    <div className='flight-number-box' style={{ height: 250 }}>
                                        <div className="input">
                                            <label className='input-label'>Arrival Flight #</label>
                                            <input type='text' placeholder='eg Arrival Flight Number' value={arrivalFlightNumber} onChange={e => setArrivalFlightNumber(e.target.value.replace(' ', ''))} />
                                        </div>
                                        <div className="input">
                                            <label className='input-label'>Departure Flight #</label>
                                            <input type='text' placeholder='eg Departure Flight Number' value={departureFlightNumber} onChange={e => setDepartureFlightNumber(e.target.value.replace(' ', ''))} />
                                        </div>
                                    </div>
                                </div>
                            </FlightNumberContainer>
                            <NextButton className='checkButton' type='submit' onClick={updateData}>CHECK</NextButton>
                        </FormContainer>
                    </OptionsContainer>
                </>
            }
        </PageContainer>
    )
}

const FlightNumberContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .flight-number-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 130px;
        padding: 15px;
        border-radius:  0 0 15px 15px;
        border: none;
        background: #ffffff;

        .input {
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 10px;
            
            input {
                width: 100%;
                margin-top: 10px;
            }
        }
        
        .input-label {
            width: 100%;
            font-size: 22px;
            font-weight: 600;
        }

        input {
            width: 95%;
            padding: 10px 15px;
            font-size: 1.5rem;
            border: none;
            outline: none;
            border-radius: 10px;
            background: #e7e7e7;
            animation: input-field infinite 250ms;
            animation-duration: 2s;


            &:focus {
                box-shadow: 0 0 0 2px var(--theme-transparent);
                animation: none;
            }
        }

        input::placeholder {
            color: #868686;
        }
    }
`
const FormContainer = styled.form`
    width: 100%;
    height: 100%;

    .checkButton {
        margin-left: auto;
        margin-right: auto;
    }
`

export default CheckAvailability