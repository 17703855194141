import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { getAllUsers, getUser, handleUpdateUserBillingAddress, handleUpdateUserServicePrices, updatePasswordFromAdmin } from '../../api/auth/handlers'
import ReactLoading from 'react-loading'
import { Loader } from '../pages/StyledComponents'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteUserConfirmationModal from './DeleteUserConfirmationModal'
import { Notification } from '../elements/Notification'
import { Button } from '../elements/Button'

const UsersModal = ({open, onHide}) => {

    const [modalTitle, setModalTitle] = useState('Select user to view')
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [user, setUser] = useState(null)

    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')

    const [newPassword, setNewPassword] = useState('')

    const [billingAddress, setBillingAddress] = useState({
        street: street,
        city: city,
        state: state,
        zip: zip,
    })

    // LGA
    const [lgaDep, setLgaDep] = useState(0)
    const [lgaArr, setLgaArr] = useState(0)
    const [lgaCon, setLgaCon] = useState(0)

    // JFK
    const [jfkDep, setJfkDep] = useState(0)
    const [jfkArr, setJfkArr] = useState(0)
    const [jfkCon, setJfkCon] = useState(0)

    // EWR
    const [ewrDep, setEwrDep] = useState(0)
    const [ewrArr, setEwrArr] = useState(0)
    const [ewrCon, setEwrCon] = useState(0)

    const [earlyMorningSurcharge, setEarlyMorningSurcharge] = useState(0)
    const [lateNightSurcharge, setLateNightSurcharge] = useState(0)

    // prices
    const [prices, setPrices] = useState({
        
        lgaDep: lgaDep,
        lgaArr: lgaArr,
        lgaCon: lgaCon,
        jfkDep: jfkDep,
        jfkArr: jfkArr,
        jfkCon: jfkCon,
        ewrDep: ewrDep,
        ewrArr: ewrArr,
        ewrCon: ewrCon,
        earlyMorningSurcharge: earlyMorningSurcharge,
        lateNightSurcharge: lateNightSurcharge,

    })

    const [loading, setLoading] = useState(false)
    const [userDeleted, setUserDeleted] = useState(false)
    
    const [deleteUserConfirmationModal, setDeleteUserConfirmationModal] = useState(false)

    useEffect(() => {
        getAllUsers().then(res => {
            setUsers(res)
        })
    }, [open, selectedUser])

    useEffect(() => {
        setBillingAddress({
            street: street,
            city: city,
            state: state,
            zip: zip,
        })
    }, [street, city, state, zip])

    useEffect(() => {
        setBillingAddress({
            street: street,
            city: city,
            state: state,
            zip: zip,
        })
    }, [street, city, state, zip])

    useEffect(() => {
        setPrices({
            lgaDep: lgaDep,
            lgaArr: lgaArr,
            lgaCon: lgaCon,
            jfkDep: jfkDep,
            jfkArr: jfkArr,
            jfkCon: jfkCon,
            ewrDep: ewrDep,
            ewrArr: ewrArr,
            ewrCon: ewrCon,
            earlyMorningSurcharge: earlyMorningSurcharge,
            lateNightSurcharge: lateNightSurcharge,
        })
    }, [lgaDep, lgaArr, lgaCon, jfkDep, jfkArr, jfkCon, ewrDep, ewrArr, ewrCon, earlyMorningSurcharge, lateNightSurcharge])

    useEffect(() => {
        if(selectedUser) {
            setLoading(true)
            getUser({email: selectedUser})
            .then((userObject) => {
                setLoading(false)
                const address = userObject.data()?.billingAddress
                const prices = userObject.data()?.prices

                setUser(userObject.data())

                if(address) {
                    setStreet(address?.street)
                    setCity(address?.city)
                    setState(address?.state)
                    setZip(address?.zip)
                } else {
                    setStreet('')
                    setCity('')
                    setState('')
                    setZip('')
                }

                if(prices) {
                    setLgaDep(prices?.lgaDep)
                    setLgaArr(prices?.lgaArr)
                    setLgaCon(prices?.lgaCon)
                    setJfkDep(prices?.jfkDep)
                    setJfkArr(prices?.jfkArr)
                    setJfkCon(prices?.jfkCon)
                    setEwrDep(prices?.ewrDep)
                    setEwrArr(prices?.ewrArr)
                    setEwrCon(prices?.ewrCon)
                    setEarlyMorningSurcharge(prices?.earlyMorningSurcharge | 0)
                    setLateNightSurcharge(prices?.lateNightSurcharge | 0)
                }
            })
        }
        // eslint-disable-next-line
    }, [selectedUser])

    const hideModal = () => {
        onHide(!true)
		setSelectedUser(null)
    }

    const handleSelectUser = (user) => {
        setModalTitle(user.email.replace('@specialservicesny.com', ''))
        setSelectedUser(user.email)
    }

    const handleChangeBillingAddress = () => {
        if(billingAddress) {
            setLoading(true)
            handleUpdateUserBillingAddress({email: selectedUser}, billingAddress)
            .then(() => {
                setLoading(false)
            })
        } else {
            alert('Enter required details')
        }
    }

    const handleUpdateServicePrices = () => {
        if(prices) {
            setLoading(true)
            handleUpdateUserServicePrices({email: selectedUser}, prices)
            .then(() => {
                setLoading(false)
            })
        }
    }
    
    const handleUpdatePassword = () => {
        if(newPassword.length >= 6) {
            setLoading(true)
            updatePasswordFromAdmin(user.userInfo.uid, newPassword)
            .then(() => {
                setNewPassword('')
                setLoading(false)
            })
        } else {
            alert("Enter new password")
        }
    }

    const goBack = () => {
        setSelectedUser(null)
        setModalTitle('Select user to view')
    }

    const  handleDeleteUser = () => {
        setDeleteUserConfirmationModal(true)
    }

    const handleChangePrice = (price, setPrice) => {
        setPrice(Number(price))
    }

    const createPriceInput = (airport, service, price, setPrice) => (
        <div className="servicePriceInput">
            <div className="label">{airport} {service}</div>
            <input className="priceInput" placeholder="$0" maxLength={6} value={`$${price}`} onChange={e => {handleChangePrice(e.target.value.replace('$', ''), setPrice)}} />
        </div>
    )

    return (
        open &&
            <>
                <Modal title={modalTitle} close={hideModal}>
                    {!selectedUser ?
                        <UsersModalContainer>
                            {users.length > 1 ? users.map(user => (
                                user.userInfo.email.split('@')[0] !== 'admin' &&
                                <div key={user.userInfo.uid} className="user" onClick={() => handleSelectUser(user.userInfo)}>
                                    <div className="username">{user.userInfo.email.replace('@specialservicesny.com', '')}</div>
                                </div>
                            )) :
                            users.length === 0 ?
                            <Loader>
                                <ReactLoading type='balls' color='#000000'/>
                            </Loader> :
                            <NoUsersFound>no users found</NoUsersFound>
                            }
                        </UsersModalContainer> :
                        <UserDetailsContainer>
                            <GoBackToUsers onClick={(goBack)}>
                                <ArrowBackIcon className='goBackArrow'/>
                            </GoBackToUsers>
                            <div className="detailsContainer">
                                {!loading ?
                                    <>
                                        <div className="servicePrices">
                                            <div className="title">Pricing</div>
                                            <div className="service">
                                                {createPriceInput('LGA', 'Departure', lgaDep, setLgaDep)}
                                                {createPriceInput('LGA', 'Arrival', lgaArr, setLgaArr)}
                                                {createPriceInput('LGA', 'Connection', lgaCon, setLgaCon)}
                                            </div>
                                            <div className="service">
                                                {createPriceInput('JFK', 'Departure', jfkDep, setJfkDep)}
                                                {createPriceInput('JFK', 'Arrival', jfkArr, setJfkArr)}
                                                {createPriceInput('JFK', 'Connection', jfkCon, setJfkCon)}
                                            </div>
                                            <div className="service">
                                                {createPriceInput('EWR', 'Departure', ewrDep, setEwrDep)}
                                                {createPriceInput('EWR', 'Arrival', ewrArr, setEwrArr)}
                                                {createPriceInput('EWR', 'Connection', ewrCon, setEwrCon)}
                                            </div>
                                            <div className="service">
                                                {createPriceInput('Early Morning', 'Surcharge', earlyMorningSurcharge, setEarlyMorningSurcharge)}
                                                {createPriceInput('Late Night', 'Surcharge', lateNightSurcharge, setLateNightSurcharge)}
                                            </div>
                                            <FormButton onClick={handleUpdateServicePrices}>UPDATE PRICING</FormButton>
                                        </div>
                                        <div className="billingAddress">
                                            <div className="title">Billing Address</div>
                                            <label>
                                                <span>Street</span>
                                                <input className="street" placeholder='Street' value={street} onChange={e => setStreet(e.target.value)} />
                                            </label>
                                            <label>
                                                <span>City</span>
                                                <input className="city" placeholder='City' value={city} onChange={e => setCity(e.target.value)} />
                                            </label>
                                            <label>
                                                <span>State</span>
                                                <input className="state" placeholder='State' value={state} onChange={e => setState(e.target.value)} />
                                            </label>
                                            <label>
                                                <span>Zip</span>
                                                <input className="zip" placeholder='Zip' value={zip} onChange={e => setZip(e.target.value)} />
                                            </label>
                                            <FormButton onClick={handleChangeBillingAddress}>UPDATE BILLING ADDRESS</FormButton>
                                        </div>
                                        <div className="updatePassword">
                                            <div className="title">Password</div>
                                            <label>
                                                <input className="password" placeholder='New Password' type='password' value={newPassword} onChange={e => setNewPassword(e.target.value.replace(' ', ''))} />
                                            </label>
                                            <FormButton onClick={handleUpdatePassword}>UPDATE PASSWORD</FormButton>
                                        </div>
                                    </> :
                                    <Loader>
                                        <ReactLoading type='balls' color='#000000'/>
                                    </Loader>
                                }
                            </div>
                            {!loading && <Button style={{margin: 'auto', marginTop: 15}} label='Delete User' color='#C93839' type='error' onClick={handleDeleteUser} />}
                        </UserDetailsContainer>
                    }
                </Modal>
                <DeleteUserConfirmationModal open={deleteUserConfirmationModal} onHide={setDeleteUserConfirmationModal} hideUserModal={onHide} user={selectedUser} goBack={goBack} setUserDeleted={setUserDeleted} />
                <Notification open={userDeleted} onHide={setUserDeleted} severity='error' title='User Deleted Successfully'/>
            </>
    )
}

const UsersModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 400px;
    overflow-y: scroll;
    position: relative;

    @media (max-width: 600px) {
        width: 100%;
    }

    .user {
        margin: 5px;
        width: 90%;
        padding: 20px 25px;
        background: #fafafa;
        border: 1px solid var(--theme);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        transition: all 350ms;

        .username {
            font-weight: 600;
            font-size: 1.5rem;
        }

        &:hover {
            opacity: 0.5;
        }
    }
`

const UserDetailsContainer = styled.div`
    width: 600px;
    height: 500px;
    padding: 15px;
    overflow: hidden;
    overflow-y: scroll;

    @media (max-width: 600px) {
        width: 100%;
    }

    &::-webkit-scrollbar {
        display: flex;
        width: 5px;
        margin: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--theme-transparent);
        border-radius: 5px;

        &:hover {
            background: var(--theme)
        }
    }

    .title {
        margin: auto;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: bold;
        padding-bottom: 5px;
        border-bottom: 3px solid var(--theme);
        margin-bottom: 25px;
    }

    .detailsContainer {
        padding-bottom: 25px;
        border-bottom: 1px solid var(--theme);
    }

    .deleteUserButton {
        margin: auto;
        margin-top: 25px;
        font-size: 1.5rem;
        width: fit-content;
        padding: 5px 15px;
        color: #ffffff;
        border-radius: 5px;
        background: #DA3840;
        user-select: none;
        cursor: pointer;
        transition: all 250ms;

        &:hover {
            opacity: 0.85;
        }
    }

    .servicePrices {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .service {
            display: flex;
            justify-content: space-between;
            margin: 15px 0;
        }

        .servicePriceInput {
            margin: 0 10px;
            width: 100%;
            text-align: center;
            
            .label {
                font-size: 1rem;
                font-weight: bold;
                margin-bottom: 5px;
            }
            
            .priceInput {
                width: 100%;
                font-size: 1.5rem;
                padding: 10px;
                text-align: center;
                font-weight: bold;
                outline-width: 0;
                border: none;
                border-radius: 5px;
                color: var(--theme);
            }
        }

    }

    .billingAddress, .updatePassword {
        display: flex;
        flex-direction: column;
        width: 100%;

        label {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 5px 0;

            span {
                background: black;
                color: white;
                padding: 5px 15px;
            }
        }
        
        input {
            margin: 5px 0;
            width: 100%;
            padding: 8px 15px;
            outline-width: 0;
            background: transparent;
            border: 2px solid var(--theme-dark);
            font-size: 1.5rem;
        }
    }

    .updatePassword {
        margin-top: 25px;
    }
`

const GoBackToUsers = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    padding: 15px;
    background: var(--theme);
    border-radius: 15px 0 15px 0;
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }

    .goBackArrow {
        font-size: 2rem;
        color: #ffffff;
    }
`

const FormButton = styled.div`
    background: var(--theme);
    padding: 8px 0;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #fafafa;
    text-transform: uppercase;
    margin: 5px;
    user-select: none;
    cursor: pointer;
`

const NoUsersFound = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
`

export default UsersModal
