import axios from 'axios'
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { collection, getDocs, doc, setDoc, getDoc, deleteDoc, arrayUnion, arrayRemove } from 'firebase/firestore'
import moment from 'moment'
import { APP_URL } from '../../config/app.config'
import { auth, db } from '../../config/firebase'
import DateTime from '../../utils/DateTime'
import { handleSendAccountActivationEmail } from '../notification/handlers'

export const handleUserLogin = async(user) => {
  const userInfo = {
    uid: user.uid,
    email: user.email,
    metadata: {...user.metadata}
  }
  await setDoc(doc(db, 'users', user.email), {
    userInfo: userInfo,
    lastSeen: DateTime.getDate().toString(),
  }, { merge: true })
}

export const handleUpdateUserServicePrices = async(user, prices) => {
  await setDoc(doc(db, 'users', user.email), {
      prices: prices,
  }, { merge: true })
}

export const handleUpdateUserBillingAddress = async(user, billingAddress) => {
  await setDoc(doc(db, 'users', user.email), {
      billingAddress: billingAddress,
  }, { merge: true })
}

export const createUser = async(email, password) => {
  return await axios.post(`${process.env.REACT_APP_AUTH_BACKEND}/createUser`, {
      email,
      password,
  })
}

export const updatePassword = async(uid, email, oldPassword, newPassword) => {
  const cred = EmailAuthProvider.credential(email, oldPassword)
  let data = null
  
  try {
      await reauthenticateWithCredential(auth.currentUser, cred)
      .then(async() => {
          // authenticated
          await axios.post(`${process.env.REACT_APP_AUTH_BACKEND}/updatePassword`, {
              uid,
              newPassword,
          }).then((res) => {
              data = res
          })
      })
  }
  catch(error) {
      data = null
      // not authenticated
      console.log(error.message);
  }

  return data
}

export const updatePasswordFromAdmin = async(uid, newPassword) => {
  let data = null
  
  try {
      await axios.post(`${process.env.REACT_APP_AUTH_BACKEND}/updatePassword`, {
          uid,
          newPassword,
      }).then((res) => {
          data = res
      })
  }
  catch(error) {
      data = null
      // not authenticated
      console.log(error.message);
  }

  return data
}

export const deleteUser = async(user) => {
  const getUserObject = doc(db, 'users', user)

  return await deleteDoc(getUserObject)
}

export const handleAddUser = async(user, email, stripeId) => {
  await setDoc(doc(db, 'users', user.email), {
      email: email,
      paymentMethods: [],
      stripeId,
      isActivated: false,
      prices: {
          lgaDep: 0,
          lgaArr: 0,
          lgaCon: 0,
          jfkDep: 0,
          jfkArr: 0,
          jfkCon: 0,
          ewrDep: 0,
          ewrArr: 0,
          ewrCon: 0,
      }
  }, { merge: true }).then(() => {
      handleUserLogin(user)
  })
}

export const activateUser = async(user, email) => {
  handleSendAccountActivationEmail(email, user.email.split('@')[0])
  await setDoc(doc(db, 'users', user.email), {
      isActivated: true,
  }, { merge: true })
}

export const generateReservationNumber = async (service) => {
  let reservationNumber
  await getReservations()
  .then((reservations) => {
      reservationNumber = (`SSNY-${(service.slice(0, 3)).toUpperCase()}-${DateTime.date().replaceAll('/', '')}-${Object.keys(reservations[`${service.toLowerCase()}s`]).length+1}`)
  })
  return reservationNumber
}

export const bookReservation = async(user, data, reservationNumber) => {
  // prepare user info object
  const userInfo = {
      uid: user.uid,
      email: user.email,
  }

  // prepare booking object
  const bookingObject = {
      reservationNumber: reservationNumber,
      bookingInfo: data,
      userInfo: userInfo,
  }

  // booking types
  const arrivals = []
  const departures = []
  const connections = []

  // create the arrival / departure / connection booking object
  switch (data.service) {
      case 'arrival':
          arrivals[reservationNumber] = bookingObject
          break;
      case 'departure':
          departures[reservationNumber] = bookingObject
          break;
      case 'connection':
          connections[reservationNumber] = bookingObject
          break;
      default:
          break;
  }

  // store booking in the database
  switch (data.service) {
      case 'arrival':
          await setDoc(doc(db, 'bookings', 'arrivals'), {
              ...arrivals,
          }, { merge: true }) 
          break;

      case 'departure':
          await setDoc(doc(db, 'bookings', 'departures'), {
              ...departures
          }, { merge: true }) 
          break;

      case 'connection':
          await setDoc(doc(db, 'bookings', 'connections'), {
              ...connections
          }, { merge: true }) 
          break;

      default:
          break;
  }
}

export const createBlockedDateOrTimeConflict = async(booking) => {
  const id = `${moment().format('DDMMYYYYhhmmssSS')}`
  const conflicts = doc(db, 'conflicts', id)
  
  await setDoc((conflicts), {
      id: id,
      // conflicting
      booking,
      type: 'DATE-TIME',
  })
  
  return `${APP_URL}/admin/conflict?id=${id}`
}

export const createReservationConflict = async(conflictingBooking, conflictedBooking) => {
  const id = `${moment().format('DDMMYYYYhhmmssSS')}`
  const conflicts = doc(db, 'conflicts', id)
  
  await setDoc((conflicts), {
      id: id,
      // conflicting
      conflictingBooking: conflictingBooking,
      // conflicted
      conflictedBooking: conflictedBooking,
      type: 'CONFLICT',
  })
  
  return `${APP_URL}/admin/conflict?id=${id}`
}

export const createReservationSameDay = async(booking) => {
  const id = `${moment().format('DDMMYYYYhhmmssSS')}`
  const conflicts = doc(db, 'conflicts', id)
  
  await setDoc((conflicts), {
      id: id,
      booking,
      type: 'SAME-DAY',
  })
  
  return `${APP_URL}/admin/conflict?id=${id}`
}

export const getReservationConflict = async(id) => {
  const conflict = doc(db, 'conflicts', id)
  
  return await getDoc(conflict)
}

export const removeFromReservationConflict = async(id) => {
  const conflict = doc(db, 'conflicts', id)
  
  return await deleteDoc(conflict)
}

export const getReservation = async(service, reservationId) => {
  const bookingsCollection = (await getDoc(doc(db, "bookings", service))).data()
  return Object.entries(bookingsCollection).find((resv => resv[1].reservationNumber === reservationId))
}

export const updateReservation = async(service, reservation) => {
  return await setDoc(doc(db, 'bookings', service), {
      ...reservation
  }, { merge: true }) 
}

export const getReservations = async() => {
  const bookingsCollection = await getDocs(collection(db, "bookings"))
  let reservationsLabels= ['arrivals', 'connections', 'departures']
  let reservations = {}
  let count = 0

  bookingsCollection.forEach(doc => {
      reservations[reservationsLabels[count]] = doc.data()
      count++
  })

  return reservations
}

export const getAllBookings = async(reservationsSetter, datesSetter) => {
  const bookingsCollection = await getDocs(collection(db, "bookings"))
  let bookings = []
  let reservationsLabels= ['arrivals', 'connections', 'departures']
  let reservations = {}
  let count = 0
  
  bookingsCollection.forEach(doc => {
      reservations[reservationsLabels[count]] = doc.data()
      if(!bookings.includes(moment(doc.data().id).format('DD-MM-YYYY'))) {
          if(Object.keys(doc.data()).length !== 0) {
              Object.values(doc.data()).forEach(booking => {
                  bookings.push(moment(booking.bookingInfo.date).format('YYYY-MM-DD'))
              })
          }
      }
      count++
  })
  
  reservationsSetter([reservations])
  datesSetter(bookings)
}

export const getAllUsers = async() => {
  const usersCollection = getDocs(collection(db, "users"))
  const users = []
  await usersCollection.then(res => {
      res.forEach(doc => {
          users.push(doc.data())
      })
  })

  return users
}

export const getUser = async(user) => {
  const getUserObject = doc(db, 'users', user.email)
  
  return await getDoc(getUserObject)
}

export const addPaymentMethodToUser = async(user, paymentMethodObject) => {
  const getUserObject = doc(db, 'users', user.email)

  return await setDoc(getUserObject, {
      paymentMethods: arrayUnion(paymentMethodObject)
  }, { merge: true })
}

export const deletePaymentMethodFromUser = async(user, paymentMethodObject) => {
  const getUserObject = doc(db, 'users', user.email)
  
  return await setDoc(getUserObject, {
      paymentMethods: arrayRemove(paymentMethodObject)
  }, { merge: true })
}

export const addChargesToHistory = async(user, chargesData) => {
  const getUserObject = doc(db, 'chargesHistory', user.email)
  
  return await setDoc(getUserObject, {
      ...chargesData
  }, { merge: true })
}

export const getChargesHistory = async(user) => {
  const getChargesHistoryObject = doc(db, 'chargesHistory', user.email)

  return await getDoc(getChargesHistoryObject)
}

export const getBlockedDates = async(setBlockedDates) => {
  const blockedDates = getDoc(doc(db, 'blocks', 'blockedDates'))

  await blockedDates.then(res => {
      if(res.data().dates) {
          setBlockedDates([...res.data().dates])
      }
  })
}

export const getBlockedTimes = async(setBlockedTimes) => {
  const blockedTimes = getDoc(doc(db, 'blocks', 'blockedTimes'))
  await blockedTimes.then(res => {
      if(res.data()) {
          setBlockedTimes([...Object.values(res.data())])
      }
  })
}

export const getFlightBlocks = async(setFlightBlocks) => {
  const flightBlocks = getDoc(doc(db, 'blocks', 'flightBlocks'))
  let blocks = []
  
  await flightBlocks.then(res => {
      if(res.data()) {
          Object.values(res.data()).forEach((time) => {
              if(!blocks.includes(time)) {
                  blocks.push(time)
              }
          })
      }
  })

  setFlightBlocks(blocks)

  return blocks
}

export const blockDate = async(dates) => {
  const getBlockObject = doc(db, 'blocks', 'blockedDates')

  return await setDoc(getBlockObject, {
      dates: arrayUnion(...dates)
  }, { merge: true })
}

export const unblockDate = async(date) => {
  const getBlockObject = doc(db, 'blocks', 'blockedDates')

  return await setDoc(getBlockObject, {
      dates: arrayRemove(date)
  }, { merge: true })
}

export const blockTime = async(timeObject) => {
  const getBlockObject = doc(db, 'blocks', 'blockedTimes')

  const blockedTimes =  (await getDoc(getBlockObject)).data()

  blockedTimes[`${timeObject.date}/${timeObject.startTime}/${timeObject.endTime}`.replace(/[\s]/, '')] = timeObject

  
  return await setDoc(getBlockObject, {
      ...blockedTimes
  }, { merge: true })
}

export const unblockTime = async(timeObject) => {
  const getBlockObject = doc(db, 'blocks', 'blockedTimes')
  
  return await setDoc(getBlockObject, {
      ...timeObject
  })
}

export const blockFlightTime = async (airport, service, dateTime, bookingObject) => {
  const getBlockObject = doc(db, 'blocks', 'flightBlocks')
  var timeObject = {}
  const flightTime = moment(dateTime).format('hh:mm a')

  
  // create logic
  if(service.toLowerCase() === 'departure' && airport === 'JFK') {
      const date = moment(dateTime).format('MM-DD-YYYY')
      const startTime = moment(dateTime).subtract(120, 'minutes').format('hh:mm a')
      const endTime = moment(dateTime).subtract(15, 'minutes').format('hh:mm a')

      // const startTimeLabel = startTime.replace(/\s/g, '').toUpperCase()
      // const endTimeLabel = endTime.replace(/\s/g, '').toUpperCase()
      
      timeObject[bookingObject.reservationNumber] = {date, startTime, endTime, flightTime, service, airport, bookingObject}
      
      return await setDoc(getBlockObject, {
          ...timeObject,
      }, { merge: true })

  } 
  
  if(service.toLowerCase() === 'departure' && airport === 'LGA') {
      const date = moment(dateTime).format('MM-DD-YYYY')
      const startTime = moment(dateTime).subtract(15, 'minutes').format('hh:mm a')
      const endTime = moment(dateTime).add(30, 'minutes').format('hh:mm a')
      
      timeObject[bookingObject.reservationNumber] = {date, startTime, endTime, flightTime, service, airport, bookingObject}

      return await setDoc(getBlockObject, {
          ...timeObject,
      }, { merge: true })

  } 
  
  if(service.toLowerCase() === 'arrival') {
    const date = moment(dateTime).format('MM-DD-YYYY')
    const startTime = moment(dateTime).subtract(1, 'hour').format('hh:mm a')
    const endTime = moment(dateTime).add(1, 'hour').format('hh:mm a')
    
    timeObject[bookingObject.reservationNumber] = {date, startTime, endTime, flightTime, service, airport, bookingObject}

    return await setDoc(getBlockObject, {
        ...timeObject,
    }, { merge: true })
  }

  if(service.toLowerCase() === 'connection') {
    const date = moment(dateTime).format('MM-DD-YYYY')
    const startTime = moment(dateTime).subtract(45, 'minutes').format('hh:mm a')
    const endTime = moment(dateTime).add(1, 'hour').format('hh:mm a')
    
    timeObject[bookingObject.reservationNumber] = {date, startTime, endTime, flightTime, service, airport, bookingObject}

    return await setDoc(getBlockObject, {
        ...timeObject,
    }, { merge: true })
  }
}

export const cancelReservation = async (service, reservation) => {
  const getReservationsObject = doc(db, 'bookings', `${service}s`)
  const getFlightBlockObject = doc(db, 'blocks', 'flightBlocks')

  let reservations
  let updatedReservations = {}
  let flightBlocks
  let updatedFlightBlocks = {}
  let response

  if(reservation.isConfirmedConflict) {
    await deleteDoc(doc(db, 'confirmedConflicts', reservation.reservationNumber))
    .then(() => {
      response = 'success'
    })
  } else {
    await getDoc(getReservationsObject).then((res) => {
      reservations = [...Object.entries(res.data())]
      reservations.filter(obj => obj[1].reservationNumber !== reservation.reservationNumber).forEach(res => {
          updatedReservations[res[0]] = res[1]
      })
  })

  await getDoc(getFlightBlockObject).then((res) => {
      flightBlocks = [...Object.entries(res.data())]

      flightBlocks.filter(obj => obj[1].bookingObject.reservationNumber !== reservation.reservationNumber).forEach(res => {
          const id = res[0]
          updatedFlightBlocks[id] = res[1]
      })
  })

  await setDoc(getReservationsObject, {
      ...updatedReservations,
  }).then(async() => {
      await setDoc(getFlightBlockObject, {
          ...updatedFlightBlocks,
      }).then(() => {
          response = 'success'
      })
  })
  }

  return response
}

export const addToConfirmedConflicts = async(email, username, booking) => {
    const confirmedConflict = doc(db, 'confirmedConflicts', booking.reservationNumber)
    
    return await setDoc((confirmedConflict), {
      email,
      username,
      booking,
    })
  }
  
  export const removeFromConfirmedConflict = async(reservationNumber) => {
  const confirmedConflict = doc(db, 'confirmedConflicts', reservationNumber)

  return await deleteDoc(confirmedConflict)
}

export const getConfirmedConflicts = async() => {
  const confirmedConflictsCollection = getDocs(collection(db, "confirmedConflicts"))

  const confirmedConflictsArray = []
  await confirmedConflictsCollection.then(res => {
      res.forEach(doc => {
        confirmedConflictsArray.push({...doc.data().booking, email: doc.data().email, username: doc.data().username, isConfirmedConflict: true})
      })
  })

  return confirmedConflictsArray
}

export const getConfirmedConflict = async(reservationNumber) => {
  const confirmedConflicts = doc(db, 'confirmedConflicts', reservationNumber)

  return await getDoc(confirmedConflicts)
}

export const updateConfirmedConflictReservation = async(reservationNumber, reservation) => {
  return await setDoc(doc(db, 'confirmedConflicts', reservationNumber), {
      ...reservation
  }, { merge: true }) 
}

export const getCalenderFlightDateTime = (airport, service, dateTime) => {

  const flightTime = moment(dateTime).format('hh:mm a')

  // create logic
  if(service.toLowerCase() === 'departure' && airport === 'JFK') {
    const date = moment(dateTime).format('MM-DD-YYYY')
    const startTime = moment(dateTime).subtract(120, 'minutes').format('hh:mm a')
    const endTime = moment(dateTime).subtract(15, 'minutes').format('hh:mm a')
    
    return {
      date,
      startTime,
      endTime,
      flightTime,
    }
  } 
  
  if(service.toLowerCase() === 'departure' && airport === 'LGA') {
    const date = moment(dateTime).format('MM-DD-YYYY')
    const startTime = moment(dateTime).subtract(15, 'minutes').format('hh:mm a')
    const endTime = moment(dateTime).add(30, 'minutes').format('hh:mm a')
    
    return {
      date,
      startTime,
      endTime,
      flightTime,
    }
  } 
  
  if(service.toLowerCase() === 'arrival') {
    const date = moment(dateTime).format('MM-DD-YYYY')
    const startTime = moment(dateTime).subtract(1, 'hour').format('hh:mm a')
    const endTime = moment(dateTime).add(1, 'hour').format('hh:mm a')
    
    return {
      date,
      startTime,
      endTime,
      flightTime,
    }
  }
  
  if(service.toLowerCase() === 'connection') {
    const date = moment(dateTime).format('MM-DD-YYYY')
    const startTime = moment(dateTime).subtract(45, 'minutes').format('hh:mm a')
    const endTime = moment(dateTime).add(1, 'hour').format('hh:mm a')
    
    return {
      date,
      startTime,
      endTime,
      flightTime,
    }
  }
}