import { scrollToBottom } from '../../utils/helpers'
import { PageContainer, OptionsContainer } from './StyledComponents'
import FlightIcon from '@mui/icons-material/Flight'

function AirportSelector({ data, setData, updatePages }) {

    const airports = ['LGA', 'JFK', 'EWR']

    const resetAirports = () => {
        airports.forEach(airport => {
            document.querySelector(`.${airport}`).classList.remove('option-active')
        })
    }

    const updateData = (data) => {
        resetAirports()
        document.querySelector(`.${data.airport}`).classList.toggle('option-active')
        setData(data)
        updatePages(pages => [...pages, 2])
        scrollToBottom()
    }

    return (
        <PageContainer>
            <OptionsContainer space='evenly' width={550} fontSize='1.35em'>
                <div className='title'>
                    <span className='text'>
                        Select an Airport
                    </span>
                    <FlightIcon className="icon" style={{ transform: 'rotate(45deg)' }} />
                    
                </div>
                <div className='options'>
                    <div className='option LGA' onClick={() => updateData({ ...data, airport: 'LGA' })}>LGA</div>
                    <div className='option JFK' onClick={() => updateData({ ...data, airport: 'JFK' })}>JFK</div>
                    <div className='option EWR' onClick={() => updateData({ ...data, airport: 'EWR' })}>EWR</div>
                </div>
            </OptionsContainer>
        </PageContainer>
    )
}

export default AirportSelector
