import './app.css'
import styled from 'styled-components'
import Header from './components/Header'
import Pages from './components/Pages'
import { useEffect, useState } from 'react'
import { auth } from './config/firebase'
import { onAuthStateChanged } from '@firebase/auth'
import { getUser, handleUserLogin } from './api/auth/handlers'

function App() {

  const [user, setUser] = useState(null)

  const [isUserActivated, setIsUserActivated]= useState(null)

  const [currentPage, setCurrentPage] = useState(1)

  const [authenticated, setAuthenticated] = useState(null)

  const appVersion = `0.1.7`

  const checkForAuthenticatedPages = (user) => {
    let pages
    if(user === 'admin') {
      pages = window.location.pathname !== '/admin' && window.location.pathname !== '/admin/conflict'
    } else {
      pages = !window.location.pathname.match(/(\/complete\/[a-zA-Z0-9]*\/SSNY-[A-Z]*-[0-9]{8}-[0-9]{1})/gi) && window.location.pathname !== '/reservations' && window.location.pathname !== '/payment'&& window.location.pathname !== '/settings' && window.location.pathname !== '/'
    }
    return pages
  }

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if(user) {
        setUser(user)
        setAuthenticated(true)
        handleUserLogin(user)        
        // if user is admin
        if(user.email.split('@')[0] === 'admin' && checkForAuthenticatedPages('admin')) {
          window.location = '/admin'
        }

        // if user is not admin
        if(user.email.split('@')[0] !== 'admin' && checkForAuthenticatedPages('user')) {
            setTimeout(() => {
              window.location = '/'
            }, 500)
        }
      } else {
        setAuthenticated(false)
        if(window.location.pathname !== '/login') {
          window.location = '/login'
        }
      }
    })
  }, [])

  const handleActivateLink = () => {
    window.location = ('/settings?ref=activateAccount')
  }

  useEffect(() => {
    if(user) {
      getUser(user).then((user) => {
        if(user.data().isActivated === true || user.data().isActivated === false) {
          setIsUserActivated(user.data().isActivated)
        } else {
          // reload the page if server doesn't respond
          user.data().userInfo.email.split('@')[0] !== 'admin' && window.location.reload()
        }
      })
    }
  }, [user])

  return (
    <AppContainer className='app'>
      {isUserActivated === false && user.email.split('@')[0] !== 'admin' && <ActivationBar>Your account is not activated. Please <span className='activateButton' onClick={handleActivateLink}>activate</span> it by changing your password.</ActivationBar>}
      <Header setCurrentPage={setCurrentPage} authenticated={authenticated} user={user} appVersion={appVersion}/>
      <Pages currentPage={currentPage} setCurrentPage={setCurrentPage} authenticated={authenticated} user={user} setUser={setUser} isUserActivated={isUserActivated} />
    </AppContainer>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  max-height: 100vh;
  overflow: scroll;
`

const ActivationBar = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  background: #DA3840;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  user-select: none;

  .activateButton {
    margin: 0 5px;
    font-weight: bold;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
  }
`

export default App;