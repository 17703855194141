import axios from 'axios'

export const getFlightStatus = async(flightData) => {

    // PRODUCTION FIREBASE BACKEND

    return await axios.post(`${process.env.REACT_APP_FLIGHTSTATS_BACKEND}/getFlightStatus`, {
        airport: flightData.airport,
        service: flightData.service,
        date: {day: flightData.date.day, month: flightData.date.month, year: flightData.date.year},
        flightNumber: flightData.flightNumber
    })

    // DEVELOPMENT LOCAL BACKEND

    // return await axios.post('http://localhost:3434/getFlightStatus', {
    //     airport: flightData.airport,
    //     service: flightData.service,
    //     date: {day: flightData.date.day, month: flightData.date.month, year: flightData.date.year},
    //     flightNumber: flightData.flightNumber
    // })
}