import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '../elements/Button'
import ReactLoading from 'react-loading'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import { cancelReservation, getCalenderFlightDateTime, getConfirmedConflicts, getFlightBlocks, getUser } from '../../api/auth/handlers'
import { handleSendCancelledReservationEmail, handleSendCancelledReservationMessage } from '../../api/notification/handlers'

const ReservationsModal = ({ open, onHide, selectedDate, reload, blockedDates, blockedTimes, setReservationDeleted}) => {

	const [reservationsAsEvents, setReservationsAsEvents] = useState([])
	const [selectedReservation, setSelectedReservation] = useState(null)
	const [showCalender, setShowCalender] = useState(false)
	const [blockedSlots, setBlockedSlots] = useState([])
	const [loading, setLoading] = useState(false)
	const [userObject, setUserObject] = useState(null)

	useEffect(() => {
		if(selectedReservation) {
			getUser(selectedReservation.userInfo)
				.then(userObject => {    
					setUserObject(userObject.data())
				})
		}
	}, [selectedReservation])

	useEffect(() => {
		// fetch flight blocks and also fetch confirmed conflicts

		(async () => {
			const confirmedConflicts = await getConfirmedConflicts()
			const flightBlocks = await getFlightBlocks(() => {})
			const allFlightBlocks = [...flightBlocks]


			confirmedConflicts.forEach((f) => {
				const airport = f.bookingInfo.airport
				const service = f.bookingInfo.service
				const dateTime = f.bookingInfo.date

				allFlightBlocks.push({
					bookingObject: {
						bookingInfo: f.bookingInfo,
						passengerName: f.bookingInfo?.passengerDetails?.passengerName,
						reservationNumber: f.reservationNumber,
						userInfo: f.userInfo,
					},
					airport,
					service,
					date: getCalenderFlightDateTime(airport, service, dateTime).date,
					startTime: getCalenderFlightDateTime(airport, service, dateTime).startTime,
					endTime: getCalenderFlightDateTime(airport, service, dateTime).endTime,
					flightTime: getCalenderFlightDateTime(airport, service, dateTime).flightTime,
					isConfirmedConflict: true,
				})
			})

			setBlockedSlots(allFlightBlocks)
			
		})()

	}, [open, selectedReservation])
	
	const hideModal = () => {
		setBlockedSlots([])
		onHide(!true)
		setShowCalender(false)
		setSelectedReservation(null)
		reload()
	}
	
	const handleCheckReservationDetails = (reservation) => {
		setSelectedReservation(reservation)
	}
	
	const handleGoBack = () => {
		setSelectedReservation(null)
	}

	const setupCalendar = (reload) => {
		const events = []
		const times = []
		const dates = []
		blockedSlots.forEach(object => {
			const format = 'MM/DD/YYYY, hh:mm a'
			const startTime = moment(`${object.date.replace('-', '/')}, ${object.startTime}`, format)
			const endTime = moment(`${object.date.replace('-', '/')}, ${object.endTime}`, format)
			const reservationEventObject = {title: `${object.airport.toUpperCase()} - ${object.service.toUpperCase()} - ${object.bookingObject.bookingInfo.passengerDetails?.passengerName || 'No Name'} - ${(object.bookingObject.bookingInfo.flightNumber).toUpperCase()} - ${(object.flightTime).toUpperCase()} ${object?.isConfirmedConflict ? 'Confirmed Conflict' : ''}`, start: new Date(startTime), end: new Date(endTime), bookingObject: object.bookingObject, backgroundColor: '#000000'}
			
			if(!events.find(object => object.title === reservationEventObject.title)) {
				events.push(reservationEventObject)
			}
		})
		
		blockedTimes.forEach(object => {
			const format = 'MM/DD/YYYY, hh:mm a'
			const startTime = moment(`${object.date.replace('-', '/')}, ${object.startTime}`, format)
			const endTime = moment(`${object.date.replace('-', '/')}, ${object.endTime}`, format)
			const timeBlockEventObject = {title: `BLOCKED`, start: new Date(startTime), end: new Date(endTime), backgroundColor: '#F50014'}
			
			if(!times.find(object => object.title === timeBlockEventObject.title)) {
				times.push(timeBlockEventObject)
			}
		})
		
		blockedDates.forEach(date => {
			const format = 'MM/DD/YYYY, hh:mm a'
			const startTime = moment(date.replace('-', '/'), format)
			const endTime = moment(date.replace('-', '/'), format).add(24, 'hours')

			// console.log(startTime)
			// console.log(endTime)

			const dateBlockEventObject = {title: `BLOCKED`, start: new Date(startTime), end: new Date(endTime), backgroundColor: '#F50014'}
			
			if(!dates.find(object => object.title === dateBlockEventObject.title)) {
				dates.push(dateBlockEventObject)
			}
		})


		setReservationsAsEvents([...events, ...times, ...dates])
	}
	
	const handleCancelReservation = () => {
		setReservationsAsEvents([])
		setLoading(true)
		cancelReservation((selectedReservation.bookingInfo.service).toLowerCase(), selectedReservation)
		.then((res) => {
			if(res === 'success') {
				handleSendCancelledReservationMessage(userObject.email, selectedReservation.userInfo.email.split('@')[0], { bookingInfo: selectedReservation.bookingInfo })
					.then(() => {
						handleSendCancelledReservationEmail(userObject.email, selectedReservation.userInfo.email.split('@')[0], { bookingInfo: selectedReservation.bookingInfo })
							.then(() => {
								reload()
								setupCalendar()
								setSelectedReservation(null)
								setLoading(false)
								setReservationDeleted(true)
								hideModal()
							})
					})
			}
		})
	}

	useEffect(() => {
		setupCalendar()

		if(open) {
			setShowCalender(false)
			setTimeout(() => {
				setShowCalender(true)
			}, 500)
		} else {
			setShowCalender(false)
		}
		// eslint-disable-next-line
	}, [open, selectedReservation])

	// useEffect(() => {
	// 	console.log(blockedSlots)
	// }, [blockedSlots])
	

	return (
			open &&
					<Modal title={!selectedReservation ? `Reservations` : `Reservation Details: ${selectedReservation.reservationNumber}`} close={hideModal}>
						{selectedReservation ? !loading ?
							<ReservationDetailsContainer>
								<Button className='backButton' icon={<ArrowBackIcon/>} label='All Reservations' color='#000000' onClick={handleGoBack} />
								<div className="section">
									<div className="section__title">Booking Info</div>
									<div className="col">
										<div className="row">
												<div className="label">User:</div>
												<div className="value">{selectedReservation.userInfo.email.split('@')[0]}</div>
										</div>
										<div className="row">
												<div className="label">Reservation Number:</div>
												<div className="value">{selectedReservation.reservationNumber}</div>
										</div>
										<div className="row">
												<div className="label">Date/Time:</div>
												<div className="value">{moment(selectedReservation.bookingInfo.date).format('MM-DD-YYYY - hh:mm a')}</div>
										</div>
										<div className="row">
												<div className="label">Service:</div>
												<div className="value">{selectedReservation.bookingInfo.service}</div>
										</div>
										<div className="row">
												<div className="label">Airport:</div>
												<div className="value">{selectedReservation.bookingInfo.airport}</div>
										</div>
										<div className="row">
												<div className="label">Flight Number:</div>
												<div className="value">{selectedReservation.bookingInfo.flightNumber}</div>
										</div>
									</div>
								</div>
								<div className="section">
									<div className="section__title">Passenger Info</div>
									<div className="section-flex" style={{ marginTop: 15 }}>
										<div className="col">
												<div className="row">
														<div className="label">Passenger Name:</div>
														<div className="value">{selectedReservation.bookingInfo?.passengerDetails?.passengerName || 'Empty'}</div>
												</div>
												<div className="row">
														<div className="label">Passenger Phone:</div>
														<div className="value">{selectedReservation.bookingInfo?.passengerDetails?.passengerPhone || 'Empty'}</div>
												</div>
												<div className="row">
														<div className="label">Driver Name:</div>
														<div className="value">{selectedReservation.bookingInfo?.passengerDetails?.driverName || 'Empty'}</div>
												</div>
												<div className="row">
														<div className="label">Driver Phone:</div>
														<div className="value">{selectedReservation.bookingInfo?.passengerDetails?.driverPhone || 'Empty'}</div>
												</div>
												<div className="row">
														<div className="label">Record Locater:</div>
														<div className="value">{selectedReservation.bookingInfo?.passengerDetails?.recordLocator || 'Empty'}</div>
												</div>
											</div>
											{selectedReservation.bookingInfo?.departureFlightDetails &&
												<div className="col">
													<div className="row">
															<div className="label">Departure Flight #:</div>
															<div className="value">{selectedReservation.bookingInfo?.departureFlightNumber || 'Empty'}</div>
													</div>
													<div className="row">
															<div className="label">Departure Date/Time:</div>
															<div className="value">{moment(selectedReservation.bookingInfo?.departureFlightDetails?.departureTime).format('MM-DD-YYYY - hh:mm a')}</div>
													</div>
													<div className="row">
															<div className="label">Departure From:</div>
															<div className="value">{selectedReservation.bookingInfo?.departureFlightDetails?.departureAirportFsCode}</div>
													</div>
													<div className="row">
															<div className="label">Departure To:</div>
															<div className="value">{selectedReservation.bookingInfo?.departureFlightDetails?.arrivalAirportFsCode}</div>
													</div>
												</div>
											}
									</div>
									<Button className='cancelReservationButton' icon={<CancelIcon />} label='Cancel Reservation' color='#C93839' type='error' onClick={handleCancelReservation}/>
								</div>
							</ReservationDetailsContainer> :
							<LoadingContainer>
								<ReactLoading type='balls' color='#000000'/>
							</LoadingContainer> :

							<ReservationsModalContainer>
								{showCalender ?
									<FullCalendar
									  nowIndicator
										plugins={[ dayGridPlugin, timeGridPlugin ]}
										initialView= 'timeGridDay'
										initialDate={selectedDate}
										headerToolbar= {
											{
												right: !isMobile ? 'prev,next today timeGridDay dayGridWeek dayGridMonth' : 'prev,next today timeGridDay',
											}
										}
										eventTimeFormat= {
											{
												hour: '2-digit',
												minute: '2-digit',
												hour12: true
											}
										}
										events={reservationsAsEvents}
										eventClick={(e) => handleCheckReservationDetails(e.event._def.extendedProps.bookingObject)}
										viewClassNames='fc_view'
										dayHeaderClassNames='fc_header'
										dayCellClassNames='fc_cell'
										slotLabelClassNames='fc_time_slot_label'
										eventClassNames='fc_event'
									/> :
									<LoadingContainer>
										<ReactLoading type='balls' color='#000000'/>
									</LoadingContainer>
								}
							</ReservationsModalContainer>
						}
					</Modal>
	)
}


const ReservationsModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 25px;
	height: 80vh;
	width: 95vw;

	@media (max-width: 600px) {
		width: 100%;
		height: 85vh;
		overflow: scroll;
		padding: 10px 0;
	}
	
	.fc_view {
		height: 100%;
		width: 100%;
		background: #ffffff;
		border-radius: 15px;
		
		@media (max-width: 600px) {
			height: 100vh;
			overflow: scroll;

			font-size: 12px;
		}
	}
	
	.fc_cell {
		background: #ffffff;
		border: 1px solid var(--theme);
		user-select: none;
		cursor: pointer;
	}

	.fc-toolbar.fc-header-toolbar {
		@media (max-width: 600px) {
			flex-direction: column;
		}

		.fc-toolbar-title {
			font-size: 2rem !important;
		}

		.fc-toolbar-chunk {
			margin-top: 10px !important;
		}

		.fc-button {
			margin: 2px;
			font-size: 1.2rem !important;
		}
	}

	.fc_header {
		background: var(--theme);
		color: #ffffff;
		user-select: none;
		height: 100%;
		font-size: 1.5rem;
		padding: 15px 0;

		@media (max-width: 600px) {
			padding: 5px 0;
		}
	}

	.fc_time_slot_label {
		color: #ffffff;
		background: var(--theme);
		font-weight: bold;
	}

	.fc_event {
		color: #ffffff;
		border: none !important;
	}

	.fc-event-title {
		max-width: 450px;
		
		@media (max-width: 600px) {
			font-size: 1rem;
			max-width: 150px;
		}
	}

	.fc-daygrid-event-harness .fc-daygrid-event {
		color: var(--theme) !important;
		border: 1px solid var(--theme) !important;
	}
`

const ReservationDetailsContainer = styled.div`
		width: 100%;
		display: flex;
		flex-direction: column;
		animation: slide-down 350ms;

		@media (max-width: 600px) {
			height: 80vh;
		}

		.backButton {
			margin-left: 25px;
		}

		.section {
			display: flex;
			flex-direction: column;
			margin: 15px 0;
			width: 100%;

			.section__title {
				margin: auto;
				margin-bottom: 15px;
				width: fit-content;
				font-size: 1.5rem;
				font-weight: 600;
				text-align: center;
				padding-bottom: 5px;
				border-bottom: 2px solid var(--theme);
			}
			
			.row {
				padding: 0 25px;
				margin: 5px 0;
				display: flex;
				align-items: center;
				font-size: 1.25rem;

				.label {
					font-weight: 500;
				}
				
				.value {
					margin-left: 15px;
					font-weight: 400;
					padding: 2.5px 10px;
					background: #D9D7D0;
					border-radius: 5px;
					text-transform: uppercase;
				}
			}

			.col {
				display: flex;
				flex-direction: column;
			}
		}

		.section-flex {
			display: flex !important;
			flex-direction: row !important;
		}

		.cancelReservationButton {
			margin: 30px auto;
		}
`

const LoadingContainer = styled.div`
	display: grid;
	place-items: center;
`

export default ReservationsModal