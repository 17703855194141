import React, { useEffect, useState } from 'react'
import { handleAddPaymentCard } from '../../api/stripe/handlers'
import Modal from './Modal'
import CreditCardInput from 'react-credit-card-input'
import { Loader, NextButton } from '../pages/StyledComponents'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { addPaymentMethodToUser, getUser } from '../../api/auth/handlers'

const AddPaymentMethodModal = ({user, open, onHide, getPaymentCards, setCardAdded}) => {

    const [loading, setLoading] = useState(false)
    const [stripeId, setStripeId] = useState(null)

    // states
    const [cardNumber, setCardNumber] = useState('')
    const [expiry, setExpiry] = useState('')
    const [cvc, setCvc] = useState('')

    const hideModal = () => {
        onHide(!true)
    }

    const getStripeId = () => {
        getUser(user)
        .then(userObject => {
            if(userObject.exists()) {
                setStripeId(userObject.data()?.stripeId)
                // console.log(userObject.data()?.stripeId)
            } else {
                // console.log('user does not exist')
            }
        })
    }

    useEffect(() => {
        getStripeId()
        // eslint-disable-next-line
    }, [])

    const handleAddCreditCard = (customerId, cardNumber, expiry, cvc) => {
        if(cardNumber && expiry && cvc) {
            setLoading(true)
            handleAddPaymentCard(customerId, cardNumber, expiry, cvc)
            .then(function (response) {
                if(response) {
                    const { status } = response 
                    if(status && status === 200) {
                        if(response.data) {
                            addPaymentMethodToUser(user, response.data)
                            .then(() => {
                                setLoading(false)
                                setCardNumber('')
                                setExpiry('')
                                setCvc('')
                                getPaymentCards()
                                hideModal()
                            })
                            .finally(() => {
                                setTimeout(() => {
                                    setCardAdded(true)
                                }, 800)
                            })
                        }
                    } else {
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }
            })
        }
    }

    return (
        open &&
            <Modal title='Add a new payment card' close={hideModal}>
                <AddPaymentMethodContainer>
                    {!loading ?
                    <AddCardContainer>
                        <CreditCardInput
                            cardNumberInputProps={{ value: cardNumber, onChange: e => setCardNumber(e.target.value) }}
                            cardExpiryInputProps={{ value: expiry, onChange: e => setExpiry(e.target.value) }}
                            cardCVCInputProps={{ value: cvc, onChange: e => setCvc(e.target.value) }}
                            containerClassName="card-number-input-container"
                            fieldClassName="card-number-input-field"
                            dangerTextClassName="danger-text"
                            />
                        <NextButton disabled={!(cardNumber && expiry && cvc)} onClick={() => handleAddCreditCard(stripeId, cardNumber, expiry, cvc)} className='add-card-button'>ADD CARD</NextButton>
                    </AddCardContainer> :

                    <Loader>
                        <ReactLoading type='balls' color='#000000'/>
                    </Loader>}
                </AddPaymentMethodContainer>
            </Modal>
    )
}

const AddPaymentMethodContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 200px;
    min-width: 80%;
`

const AddCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 25px;
    width: 100%;

    .card-number-input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

       @media (max-width: 480px) {
            #field-wrapper {
                align-items: center !important;
                flex-direction: column;
                height: 250px;
                padding: 30px 0px;
            }

            label {
                display: flex;
                justify-content: center;;
                align-items: center;;
                width: 100%;
                
                input {
                    min-width: 200px;
                    text-align: center;
                }
            }
        }
    }
    
    .card-number-input-field {
        border-radius: 15px;
        margin: 15px;
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        padding: 20px;
        width: 100%;
        border: 1px solid var(--blue);

        #card-number {
            font-size: 1.25rem;
            font-weight: 600;
        }

        label {
            &:first-of-type {
                width: 50%;
            }
        }
        
        input {
            padding: 5px;
            margin: 5px;
            border-color: var(--blue) !important;
            &:focus {
                box-shadow: 0 1px 0 0 var(--blue);
            }
        }
    }

    .is-invalid {
        border-color: red !important;
    }

    .danger-text {
        font-size: 1.2rem;
        margin-bottom: 25px;
    }
`

export default AddPaymentMethodModal
