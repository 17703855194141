import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import ReactLoading from 'react-loading'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { enUS } from 'date-fns/locale'
import DateTime from '../../utils/DateTime'
import { blockDate, blockTime } from '../../api/auth/handlers'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import TimePicker from '../elements/TimePicker'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Notification } from '../elements/Notification'
import { ArrowDownward } from '@mui/icons-material'

const BlockDateTimeModal = ({open, onHide, getBlockedDateTimes}) => {

    const [loading, setLoading] = useState(false)
    const [dateBlocked, setDateBlocked] = useState(false)
    const [timeBlocked, setTimeBlocked] = useState(false)
    const [tabsValue, setTabsValue] = useState(0)

    // BLOCK DATES

    const [dates, setDates] = useState([
        {
            startDate: DateTime.getDate(),
            endDate: DateTime.getDate(),
            key: 'selection'
        }
    ])

    const [startDate, setStartDate] = useState(dates[0].startDate)
    const [endDate, setEndDate] = useState(dates[0].endDate)

    // BLOCK TIME

    const [blockTimeDate, setBlockTimeDate] = useState(DateTime.getDate())

    const [startHour, setStartHour] = useState(moment(DateTime.getDate()).format('hh'))
    const [startMinute, setStartMinute] = useState(moment(DateTime.getDate()).format('mm'))
    const [startPeriod, setStartPeriod] = useState(moment(DateTime.getDate()).format('a').toUpperCase())

    const [endHour, setEndHour] = useState(moment(DateTime.getDate()).add(1, 'hour').format('hh'))
    const [endMinute, setEndMinute] = useState(moment(DateTime.getDate()).add(1, 'hour').format('mm'))
    const [endPeriod, setEndPeriod] = useState(moment(DateTime.getDate()).add(1, 'hour').format('a').toUpperCase())

    const hideModal = () => {
        setTabsValue(0)
        onHide(!true)
    }

    const handleChange = (event, newValue) => {
        setTabsValue(newValue)
    }


    function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
    }

    function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    }

    const handleUpdateDates = (item) => {
        setStartDate(item[0].startDate)
        setEndDate(item[0].endDate)
        setDates(item)
    }

    var getDaysArray = (start, end) => {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            // push to dates array as EST DATE
            arr.push(moment(new Date(dt)).format('MM-DD-YYYY'))
        }
        return arr
    }
    
    const handleBlockTime = () => {
        setLoading(true)

        const startTimeHour = Number(startHour).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const endTimeHour = Number(endHour).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const startTimeMinute = Number(startMinute).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        const endTimeMinute = Number(endMinute).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        
        const date = moment(blockTimeDate).format('MM-DD-YYYY')
        const startTime = `${startTimeHour}:${startTimeMinute} ${startPeriod}`
        const endTime = `${endTimeHour}:${endTimeMinute} ${endPeriod}`
        const timeObject = {date, startTime, endTime}

        blockTime(timeObject)
        .then(() => {
            setLoading(false)
            setTimeBlocked(true)
            getBlockedDateTimes()
        })
    }

    const handleBlockDate = () => {
        setLoading(true)
        blockDate(getDaysArray(startDate, endDate))
        .then(() => {
            setLoading(false)
            setDateBlocked(true)
            getBlockedDateTimes()
        })
    }

    return (
        open &&
            <Modal title='Block Dates/Times' close={hideModal}>
                <BlockDateTimeModalContainer>
                    <TabsContainer>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs className='tabs' value={tabsValue} onChange={handleChange} aria-label="block date time tabs">
                                <Tab label='BLOCK DATE' {...a11yProps(0)} className='tab'/>
                                <Tab label='BLOCK TIME' {...a11yProps(1)} className='tab'/>
                            </Tabs>
                        </Box>
                    </TabsContainer>
                    
                    <TabPanel value={tabsValue} index={0}>
                        <DatePickerContainer>
                            <CalendarContainer>
                                <DateRange
                                    className='datePicker'
                                    fixedHeight
                                    minDate={DateTime.getDate()}
                                    locale={enUS}
                                    rangeColors= {['#000000']}
                                    color= '#000000'
                                    editableDateInputs={true}
                                    onChange={item => handleUpdateDates([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={dates}
                                />
                                {!loading ? <BlockButton onClick={handleBlockDate}>Block Date</BlockButton> : <Loader><ReactLoading type='balls' color='#000000'/></Loader>}
                            </CalendarContainer>
                        </DatePickerContainer>
                    </TabPanel>

                    <TabPanel value={tabsValue} index={1}>
                        <div className="blockTimeDatePicker">
                            <div className="dPicker_title">Select Date</div>
                            <DatePicker className='dPicker' selected={blockTimeDate} onChange={(date) => setBlockTimeDate(date)} dateFormat="MM-dd-yyyy"/>
                        </div>
                        <TimePickerContainer>
                            <TimePicker timePickerTitle='Start Time' hour={startHour} setHour={setStartHour} minute={startMinute} setMinute={setStartMinute} period={startPeriod} setPeriod={setStartPeriod}/>
                            <ArrowForwardIcon className='timeForwardIcon'/>
                            <ArrowDownward className='timeDownwardIcon'/>
                            <TimePicker timePickerTitle='End Time' hour={endHour} setHour={setEndHour} minute={endMinute} setMinute={setEndMinute} period={endPeriod} setPeriod={setEndPeriod}/>
                        </TimePickerContainer>
                        {!loading ? <BlockButton onClick={handleBlockTime}>Block Time</BlockButton> : <Loader><ReactLoading type='balls' color='#000000'/></Loader>}
                    </TabPanel>
                </BlockDateTimeModalContainer>
                <Notification open={dateBlocked} onHide={setDateBlocked} severity='error' title='Date Blocked Successfully'/>
                <Notification open={timeBlocked} onHide={setTimeBlocked} severity='error' title='Time Blocked Successfully'/>
            </Modal>
    )
}

const BlockDateTimeModalContainer = styled.div`
    width: 600px;
    height: 550px;
    overflow: scroll;

    @media (max-width: 720px) {
        width: auto !important;
    }

    .button {
        margin-top: 25px;
        padding: 5px 35px;
        background: var(--theme);
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 600;
        border-radius: 8px;
        user-select: none;
        cursor: pointer;
        transition: all 250ms;
        width: fit-content;

        &:hover {
            background: var(--theme-dark);
        }
    }

    .blockTimeDatePicker {
        margin-top: 15px;

        .dPicker_title {
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 600;
        }

        .dPicker {
            margin-top: 5px;
            text-align: center;
            width: 100%;
            font-size: 2.5rem;
            padding: 10px 0;
            outline-width: 0;
            border: none;
            border-radius: 10px;
            color: var(--theme);
            background: #ffffff;

        }

        .react-datepicker__triangle {
            display: none !important;
        }

        .react-datepicker-popper {
            inset: 0 auto auto 25% !important;
        }
        
        .react-datepicker {
            animation: slide-down 250ms;
            box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        }
        
        .react-datepicker__header,
        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
            background: var(--theme) !important;
            * {
                color: #ffffff !important;
            }
        }

    }
`

const BlockButton = styled.div`
    margin-top: 15px;
    width: fit-content;
    padding: 5px 15px;
    font-size: 1.5rem;
    color: #fafafa;
    background: #C93839;
    user-select: none;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

const CalendarContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Loader = styled.div`
    display: grid;
    place-items: center;
`

const TabsContainer = styled.div`
    .tabs {
        .tab {
            width: 50%;
        }
        .MuiTab-root {
            color: var(--theme);
            font-weight: bold;
            font-size: 1.2rem;
        }
        .MuiTabs-indicator {
            background-color: var(--theme);
        }
    }

`

const DatePickerContainer = styled.div`
    display: flex;
    width: 100%;
    
    .datePicker {
        align-self: center;
        width: 95%;
        border-radius: 8px;
        overflow: hidden;
        background: var(--theme-bg);

        .rdrDayToday {
            span {
                font-weight: 800;
            }
        }

        .rdrDayNumber {
            span::after{
                background: none !important;
            }
        }

        .rdrDayPassive {
            .rdrDayNumber {
                span {
                    color: #00000025;
                }
            }
        }

        .rdrMonthPicker, .rdrYearPicker {
            select {
                font-weight: 800;
                font-size: 0.95rem;
            }
        }

        .rdrMonth{
            width: 100%;
        }

        .rdrDateInput, .rdrDateDisplayItem {
            font-weight: 600;
        }

        .rdrWeekDay {
            font-weight: 600;
        }
        
        .rdrDayNumber {
            font-size: 1.15rem;
            font-weight: 800 !important;
        }
    }
`

const TimePickerContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    
    @media (max-width: 600px) {
        flex-direction: column;
    }

    .timeForwardIcon,
    .timeDownwardIcon {
        color: var(--theme);
        font-size: 3.5rem;
        margin-top: 45px;

        @media (max-width: 600px) {
            margin: 0;
            transform: translateX(-35px);
        }
    }

    .timeForwardIcon {
        @media (max-width: 600px) {
            display: none;
        }
    }

    .timeDownwardIcon {
        display: none;
        @media (max-width: 600px) {
            display: block;
        }
    }

    .startTime, .endTime {
        width: 100%;
    }

    .timeInput {
        width: 100%;
        padding: 10px;
        font-size: 1.5rem;
        border: none;
        outline-width: 0;
        color: #ffffff;
        background: var(--theme);
        border-radius: 10px;

        &::placeholder {
            color: #ffffff;
        }
    }
`

export default BlockDateTimeModal