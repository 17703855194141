import styled from 'styled-components'
import Modal from "./Modal"
import EventNoteIcon from '@mui/icons-material/EventNote'
import { isMobile } from 'react-device-detect'
import { unblockDate, unblockTime } from '../../api/auth/handlers'
import { useEffect } from 'react'

const BlockedDateTimeModal = ({open, onHide, blockedDates, blockedTimes, getBlockedDateTimes}) => {

	useEffect(() => {
		getBlockedDateTimes()
		// eslint-disable-next-line
	}, [open])

	const hideModal = () => {
		onHide(!true)
	}

	const handleUnblockDate = (date) => {
		unblockDate(date)
			.then(() => {
				getBlockedDateTimes()
			})
		}
		
		const handleUnblockTime = (date, startTime, endTime) => {
			const timeObject = blockedTimes.filter(obj => !(obj.date === date && obj.startTime === startTime && obj.endTime === endTime))
			// console.log(timeObject);
			unblockTime(timeObject)
			.then(() => {
				getBlockedDateTimes()
			})
	}

	return (
		open &&
			<Modal title='Unblock Dates/Times' close={hideModal}>
				<BlockedDateTimeModalContainer>
					{blockedDates.length > 0 && <div className="blocks-container-row">
						<div className="row-title">Blocked Dates</div>
						<div className="dates-list">
							{blockedDates.map((date) => (
								<div className="date-row" key={date}>
									<div className="date-label">
										<div className="date"><EventNoteIcon className='icon'/> {date}</div>
									</div>
									<div className="date-line" />
									<button className="unblock-button styled-button" onClick={() => handleUnblockDate(date)}>Unblock</button>
								</div>
							))}
						</div>
					</div>}
					{blockedTimes.length > 0 && <div className="blocks-container-row">
						<div className="row-title">Blocked Times</div>
						<div className="dates-list">
							{blockedTimes.map((object) => (
								<div className="date-row" key={object.startTime}>
									<div className="date-label">
										<div className="date"><EventNoteIcon className='icon'/> {object.date}</div>
										<div className="time">{object.startTime} - {object.endTime}</div>
									</div>
									<div className="date-line" />
									<button className="unblock-button styled-button" onClick={() => handleUnblockTime(object.date, object.startTime, object.endTime)}>Unblock</button>
								</div>
							))}
						</div>
					</div>}
					{blockedDates.length === 0 && blockedTimes.length === 0 &&
						<div className="nothing-blocked">There are no blocked dates or times!</div>
					}
				</BlockedDateTimeModalContainer>
			</Modal>
	)
}

const BlockedDateTimeModalContainer = styled.div`
	width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 50vh;
	max-height: 80vh;
	overflow-y: scroll;

	@media (max-width: 600px) {
		width: 100%;
	}

	&::-webkit-scrollbar {
        display: flex;
        width: 5px;
        margin: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--theme-transparent);
        border-radius: 5px;

        &:hover {
            background: var(--theme)
        }
    }

	.blocks-container-row {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin: 15px 0;
		user-select: none;
		
		.row-title {
			margin: auto;
			margin-bottom: 10px;
			width: fit-content;
			font-size: 1.35rem;
			font-weight: 600;
			text-transform: uppercase;
			padding: 5px 15px;
			color: #DD3445;
			border: 2.5px solid #DD3445;
		}
		
		.dates-list {
			margin: 15px 0;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			
			.date-row {
				width: ${isMobile ? '90%' : 'fit-content'};
				margin: 5px 0;
				padding: 25px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #D2CFCB;
				border-radius: 15px;
				
				.date-label {
					width: 200%;
					font-size: ${isMobile ? '0.8rem' : '1.25rem'};
					font-weight: 600;
					padding: 5px;
					color: #ffffff;
					background: var(--theme);
					border-radius: 10px;

					.date, .time {
						text-align: center;
						margin: 2.5px 0;
					}

					.date {
						display: flex;
						align-items: center;
						justify-content: center;
						.icon {
							font-size: 1.35rem;
							margin-right: 5px;
						}
					}
					
					.time {
						margin: auto;
						margin-top: 3px;
						border-radius: 5px;
						width: fit-content;
						color: var(--theme);
						padding: 5px 15px;
						background: var(--theme-bg);
						font-weight: bold;
					}
				}



				.date-line {
					width: 150px;
					height: 2px;
					background: #DD3445;
				}
				
				.unblock-button {
					font-size: 1.25rem;
					background: #DD3445;
				}
			}
		}
	}

	.nothing-blocked {
		display: grid;
		height: 50vh;
		width: 100%;
		place-items: center;
		font-size: 1.25rem;
		font-weight: bold;
	}
`

export default BlockedDateTimeModal