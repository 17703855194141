import { useEffect } from 'react'
import styled from 'styled-components'
import '../../animations.css'
import { removeFromConfirmedConflict } from '../../api/auth/handlers'
import { PageContainer } from './StyledComponents'

const ThankYouPage = ({ refPage, reservationNumber }) => {

    useEffect(() => {
        if(refPage === 'complete_booking') {
            removeFromConfirmedConflict(reservationNumber)
        }
        // eslint-disable-next-line
    }, [refPage])

    return (
        <PageContainer>
            <ThankYouPageContainer>
                <span className='container'>
                    <div className="text">
                    Thank you for trusting us with your business.<br />
                    <b>Please check your email for your reservation confirmation.</b>
                    </div>
                </span>
                <img src={require('../../images/plane-thankyoupage.jpeg')} alt="thankyou-page" />
            </ThankYouPageContainer>
        </PageContainer>
    )
}

const ThankYouPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;
    
    img {
        height: 300px;
        margin-top: 15px;
        border-radius: 15px;
    }
    
    .container {
        color: #ffffff;
        animation: zoom-in 250ms;
        text-align: center;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        background-size: cover;
        background-repeat: no-repeat;
        
        .text {
            font-weight: bold;
            font-size: 1.5rem;
            padding: 20px 45px;
            width: fit-content;
            background: #ffffff;
            color: var(--theme);
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
            border-radius: 10px;
        }
        
        @media (max-width: 480px) {
            font-size: 1em;
            width: 90%;
        }
    }
`

export default ThankYouPage