import styled from 'styled-components'
import '../../animations.css'
import { PageContainer } from './StyledComponents'

const NotAvailablePage = () => {
    return (
        <PageContainer>
            <NotAvailablePageContainer>
                <img className='image' alt='conflict-page' src={require('../../images/agent-conflictpage.png')}/>
                <span className='container'>A member of our team will be in contact with you shortly.</span>
            </NotAvailablePageContainer>
        </PageContainer>
    )
}

const NotAvailablePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;

    .container {
        animation: zoom-in 250ms;
        padding: 25px;
        font-size: 1.6rem;
        font-weight: 600;
        width: 60%;
        text-align: center;
        border-radius: 10px;
        background: #ffffff;
        color: var(--theme);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

        @media (max-width: 480px) {
            font-size: 1em;
            width: 90%;
        }
    }

    .image {
        height: 300px;
    }
`

export default NotAvailablePage