import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import ReactLoading from 'react-loading'
import { handleUpdateUserBillingAddress, getUser } from '../../api/auth/handlers'

const EditBillingAddressModal = ({user, open, onHide}) => {

    const [loading, setLoading] = useState(false)
    const [billingAddressUpdated, setBillingAddressUpdated] = useState(false)
    
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [billingAddress, setBillingAddress] = useState({
        street: street,
        city: city,
        state: state,
        zip: zip,
    })

    useEffect(() => {
        setBillingAddress({
            street: street,
            city: city,
            state: state,
            zip: zip,
        })
    }, [street, city, state, zip])
    
    const handleChangeBillingAddress = () => {
        if(billingAddress) {
            setLoading(true)
            handleUpdateUserBillingAddress(user, billingAddress)
            .then(() => {
                setLoading(false)
                resetFields()
                setBillingAddressUpdated(true)
            })
        } else {
            alert('Enter required details')
        }
    }

    const resetFields = () => {
        setLoading(false)
    }

    const hideModal = () => {
        setBillingAddressUpdated(false)
        onHide(!true)
    }

    useEffect(() => {
        getUser(user)
        .then((userObject) => {
            const address = userObject.data()?.billingAddress
            if(address) {
                setStreet(address?.street)
                setCity(address?.city)
                setState(address?.state)
                setZip(address?.zip)
            }
        })
        // eslint-disable-next-line
    }, [])

    return (
        open &&
            <Modal title='Edit Billing Address' close={hideModal}>
                <EditBillingAddressModalContainer>
                    {!billingAddressUpdated && !loading && 
                       <div className="billing-address">
                           <label>
                               <span>Street</span>
                               <input className="street" placeholder='Street' value={street} onChange={e => setStreet(e.target.value)} />
                           </label>
                           <label>
                               <span>City</span>
                               <input className="city" placeholder='City' value={city} onChange={e => setCity(e.target.value)} />
                           </label>
                           <label>
                               <span>State</span>
                               <input className="state" placeholder='State' value={state} onChange={e => setState(e.target.value)} />
                           </label>
                           <label>
                               <span>Zip</span>
                               <input className="zip" placeholder='Zip' value={zip} onChange={e => setZip(e.target.value)} />
                           </label>
                           <Button onClick={handleChangeBillingAddress}>UPDATE</Button>
                       </div> 
                    }
                    {loading &&
                        <Loader>
                            <ReactLoading type='balls' color='#000000'/>
                        </Loader>
                    }
                    {billingAddressUpdated &&
                        <div className="billing-address-updated-container">
                            <div className="container-title">Billing Address Updated Successfully</div>
                            <Button onClick={hideModal}>Done</Button>
                        </div>
                    }
                </EditBillingAddressModalContainer>
            </Modal>
    )
}

const EditBillingAddressModalContainer = styled.div`
    width: 450px;
    height: 100%;
    padding: 15px;

    @media (max-width: 480px) {
        width: 100%;
    }

    .billing-address {
        display: flex;
        flex-direction: column;
        width: 100%;

        label {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 5px 0;

            span {
                background: black;
                color: white;
                padding: 5px 15px;
            }
        }
        
        input {
            margin: 5px 0;
            width: 100%;
            padding: 8px 15px;
            outline-width: 0;
            background: transparent;
            border: 2px solid var(--theme-dark);
            font-size: 1.5rem;
        }

        .street {

        }
    }

    .billing-address-updated-container {
        display: flex;
        flex-direction: column;
        .container-title {
            margin: 15px;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
    `

const Loader = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    `

const Button = styled.div`
    background: var(--theme);
    padding: 8px 0;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #fafafa;
    text-transform: uppercase;
    margin: 5px;
    user-select: none;
    cursor: pointer;
    `

export default EditBillingAddressModal