import { useEffect, useState } from 'react'
import Routes from '../Routes'
import { getFlightStatus } from '../api/flightstats/handlers'
// import { getBlockedDates } from '../api/auth/handlers'
import Loading from './elements/Loading'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../config/firebase'
import moment from 'moment'
import { handleSendBlockedDateOrTimeMessage, handleSendBookingConflictMessage, handleSendBookingSameDayMessage } from '../api/notification/handlers'
import { Notification } from './elements/Notification'
import { createBlockedDateOrTimeConflict, createReservationConflict, createReservationSameDay, getBlockedDates, getBlockedTimes, getFlightBlocks, getUser } from '../api/auth/handlers'

const Pages = ({currentPage, setCurrentPage, authenticated, user, setUser, isUserActivated}) => {
    const [data, setData] = useState(null)
    const [pages, updatePages] = useState([1])
    const [availability, setAvailability] = useState(null)
    const [flightNotFound, setFlightNotFound] = useState(null)
    const [invalidFlightEntry, setInvalidFlightEntry] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allBookings, updateAllBookings] = useState([])
    const bookings = getDocs(collection(db, "bookings"))
    const [blockedDates, setBlockedDates] = useState([])
    const [blockedTimes, setBlockedTimes] = useState([])
    const [flightBlocks, setFlightBlocks] = useState([])
    const [incorrectFlightNumber, setIncorrectFlightNumber] = useState(false)
    const [userObject, setUserObject] = useState(null)

    useEffect(() => {
        if(user) {
            getUser(user)
            .then((object) => {
                setUserObject(object.data())
                // console.log(object.data())
            })
        }
    }, [user])

    useEffect(() => {
        getBlockedDateTimes()
    }, [])

    const getBlockedDateTimes = () => {
        getBlockedDates(setBlockedDates)
        getBlockedTimes(setBlockedTimes)
        getFlightBlocks(setFlightBlocks)
    }

    useEffect(() => {
        bookings.then(res => {
            res.forEach(doc => {
                if(!allBookings.includes(doc.id)) {
                    updateAllBookings(allBookings => [...allBookings, doc.id])
                }
            })
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(pages === 'detailsPage') {
            setLoading(true)

            if(data.service !== 'connection') {
                initializeArrivalDeparureBooking()
            } else {
                initializeConnectionBooking()
            }
        }
        // eslint-disable-next-line
    }, [pages])

    const checkForBlockedDate = (inputDate) => {
        let isBlocked = false
        const flightDate = moment(inputDate).format('MM-DD-YYYY')

        if(blockedDates.find(d => d === flightDate)) {
            isBlocked = true
        }

        return isBlocked
    }

    const checkForBlockedTime = (inputTime) => {
        let isBlocked = false

        const format = 'MM/DD/YYYY, hh:mm a'
        
        const flightTime = moment(moment(inputTime).format('MM/DD/YYYY, hh:mm a'), format)
        
        blockedTimes.forEach((timeObject) => {
            const startTime = moment(`${timeObject.date.replace('-', '/')}, ${timeObject.startTime}`, format)
            const endTime = moment(`${timeObject.date.replace('-', '/')}, ${timeObject.endTime}`, format)
            
            if(flightTime.isBetween(startTime, endTime) || flightTime.isSame(startTime) || flightTime.isSame(endTime)) {
                isBlocked = true
            }
        })

        return isBlocked
    }

    const checkForFlightBlock = (inputTime) => {
        let isBlocked = false

        const format = 'MM/DD/YYYY, hh:mm a'
        const actualFlightTime = moment(inputTime)
        let startBlockTime
        let endBlockTime

        if(data.service === 'departure' && data.airport === 'JFK') {
            startBlockTime = moment(inputTime).subtract(120, 'minutes')
            endBlockTime = moment(inputTime).subtract(15, 'minutes')
        }

        if(data.service === 'departure' && data.airport === 'LGA') {
            startBlockTime = moment(inputTime).subtract(15, 'minutes')
            endBlockTime = moment(inputTime).add(30, 'minutes')
        }

        if(data.service === 'arrival') {
            startBlockTime = moment(inputTime).subtract(1, 'hour')
            endBlockTime = moment(inputTime).add(1, 'hour')
        }
        
        if(data.service === 'connection') {
            startBlockTime = moment(inputTime).subtract(45, 'minutes')
            endBlockTime = moment(inputTime).add(1, 'hour')
        }

        // console.log('actual time', actualFlightTime.format('hh:mm a'))
        // console.log('start block time', startBlockTime.format('hh:mm a'))
        // console.log('end block time', endBlockTime.format('hh:mm a'))

        flightBlocks.forEach((timeObject) => {
            const startTime = moment(`${timeObject.date.replace('-', '/')}, ${timeObject.startTime}`, format)
            const endTime = moment(`${timeObject.date.replace('-', '/')}, ${timeObject.endTime}`, format)
            const time = moment(`${timeObject.date.replace('-', '/')}, ${timeObject.flightTime}`, format)

            // console.log('block time start', startTime)
            // console.log('block time end', endTime)
            
            if(actualFlightTime.isBetween(startTime, endTime) || actualFlightTime.isSame(startTime) || actualFlightTime.isSame(endTime) || actualFlightTime.isSame(time)) {
                isBlocked = timeObject
            }
            
            if(startBlockTime.isBetween(startTime, endTime) || startBlockTime.isSame(startTime) || startBlockTime.isSame(endTime) || startBlockTime.isSame(time) || endBlockTime.isBetween(startTime, endTime) || endBlockTime.isSame(startTime) || endBlockTime.isSame(endTime) || endBlockTime.isSame(time)) {
                isBlocked = timeObject
            }
        })

        // console.log(isBlocked)

        return isBlocked
    }

    // useEffect(() => {
    //     console.log(data);
    // }, [data])

    const initializeArrivalDeparureBooking = () => {
        getFlightStatus(data)
            .then(async(res) => {

                const flights = res.data.scheduledFlights

                if(flights) {
                    const flight = data.service === 'arrival' ? flights.find(flight => flight.arrivalAirportFsCode === data.airport.toUpperCase()) : flights.find(flight => flight.departureAirportFsCode === data.airport.toUpperCase())
                    const airlineName = res.data.appendix?.airlines.find(airline => airline.fs === flight?.carrierFsCode)
                    
                    if(flight) {
                        
                        // booking conflict constants \/
        
                        let conflictingBooking
        
                        if(data.service === 'arrival') {
                            conflictingBooking = {...data, airlineName, flightFromDetails: {from: flight?.departureAirportFsCode, time: flight?.departureTime}, date: flight?.arrivalTime, username: userObject.userInfo.email.split('@')[0]}
                        } else {
                            conflictingBooking = {...data, airlineName, flightToDetails: {to: flight?.arrivalAirportFsCode, time: flight?.arrivalTime}, date: flight?.departureTime, username: userObject.userInfo.email.split('@')[0]}
                        }
                        const conflictedBooking = data.service === 'arrival' ? checkForFlightBlock(flight.arrivalTime) : checkForFlightBlock(flight.departureTime)
    
                        // update data values and append flight data
                        if(data.service === 'arrival') {
                            setData({...data, airlineName, terminal: flight?.arrivalTerminal, flightFromDetails: {from: flight?.departureAirportFsCode, time: flight?.departureTime}, date: flight?.arrivalTime})
                        } else {
                            setData({...data, airlineName, terminal: flight?.departureTerminal, flightToDetails: {to: flight?.arrivalAirportFsCode, time: flight?.arrivalTime}, date: flight?.departureTime})
                        }
    
                        // logic for  the availability
                        if(data.service === 'arrival' && !allBookings.includes(flight?.arrivalTime)) {
                            // if service type is arrival
                            
                            if(!checkForBlockedDate(flight.arrivalTime) && !checkForBlockedTime(flight.arrivalTime) && !checkForFlightBlock(flight.arrivalTime)) {
                                if(flight.arrivalAirportFsCode === data.airport.toUpperCase()) {
                                    const dateMatched = moment(flight?.arrivalTime).format('DD') === data.date.day
                                    const monthMatched = moment(flight?.arrivalTime).format('MM') === (data.date.month)
                                    const timeMatched = moment(flight?.arrivalTime)
                                    const remainingTime = moment.duration(timeMatched.diff(moment())).asHours().toFixed(1)
                        
                                    // console.log(dateMatched);
                                    // console.log(monthMatched);
                                    // console.log(remainingTime >= 12);
        
                                    if(dateMatched && monthMatched && remainingTime >= 12) {
                                        setAvailability(true)
                                        setLoading(false)
                                    } else {

                                        // handle same-day reservation conflict
                                        createReservationSameDay(conflictingBooking).then((conflictLink) => {
                                            handleSendBookingSameDayMessage(conflictingBooking, conflictLink).then(() => {
                                                console.log('same-day conflict')
                                                setAvailability(false)
                                                setLoading(false)
                                            })
                                        })
                                    }
                                } else {
                                    setLoading(false)
                                    setIncorrectFlightNumber(true)
                                    updatePages([1])
                                }
                            } else {
                                
                                // handle reservation conflict
                                createReservationConflict(conflictingBooking, conflictedBooking).then((conflictLink) => {
                                    handleSendBookingConflictMessage(conflictingBooking, conflictedBooking, conflictLink)
                                    .then(() => {
                                        console.log('reservation conflict')
                                        setLoading(false)
                                        setAvailability(false)
                                    })
                                })
                            }                    
                        } else if (data.service === 'departure' && !allBookings.includes(flight?.departureTime)) {
                            // if service type is departure
    
                            if(!checkForBlockedDate(flight.departureTime) && !checkForBlockedTime(flight.departureTime) && !checkForFlightBlock(flight.departureTime)) {
                                if(flight.departureAirportFsCode === data.airport.toUpperCase()) {
                                    const dateMatched = moment(flight?.departureTime).format('DD') === data.date.day
                                    const monthMatched = moment(flight?.departureTime).format('MM') === (data.date.month)
                                    const timeMatched = moment(flight?.departureTime)
                                    const remainingTime = moment.duration(timeMatched.diff(moment())).asHours().toFixed(1)
                                    
                                    // console.log(dateMatched);
                                    // console.log(monthMatched);
                                    // console.log(remainingTime >= 12);
                                    
                                    if(dateMatched && monthMatched && remainingTime >= 12) {
                                        setAvailability(true)
                                        setLoading(false)
                                    } else {
                                        // handle same-day reservation conflict
                                        createReservationSameDay(conflictingBooking).then((conflictLink) => {
                                            handleSendBookingSameDayMessage(conflictingBooking, conflictLink)
                                            .then(() => {
                                                console.log('same-day conflict')
                                                setAvailability(false)
                                                setLoading(false)
                                            })
                                        })
                                    }
                                } else {
                                    setLoading(false)
                                    setIncorrectFlightNumber(true)
                                    updatePages([1])
                                }
                            } else {
                                
                                if(checkForBlockedDate(flight.departureTime) || checkForBlockedTime(flight.departureTime)) {
                                    // handle blocked date/time conflict
                                    createBlockedDateOrTimeConflict(conflictingBooking).then((conflictLink) => {
                                        handleSendBlockedDateOrTimeMessage(conflictingBooking, conflictLink)
                                        .then(() => {
                                            console.log('blocked date/time conflict')
                                            setAvailability(false)
                                            setLoading(false)
                                        })
                                    })
                                } else {
                                    // handle reservation conflict
                                    createReservationConflict(conflictingBooking, conflictedBooking).then((conflictLink) => {
                                        handleSendBookingConflictMessage(conflictingBooking, conflictedBooking, conflictLink)
                                        .then(() => {
                                            console.log('reservation conflict')
                                            setAvailability(false)
                                            setLoading(false)
                                        })
                                    })
                                }   
                            }                      
                        } else {
                            // handle reservation conflict
                            createReservationConflict(conflictingBooking, conflictedBooking).then((conflictLink) => {
                                handleSendBookingConflictMessage(conflictingBooking, conflictedBooking, conflictLink)
                                .then(() => {
                                    console.log('reservation conflict')
                                    setLoading(false)
                                    setAvailability(false)
                                })
                            })
                        }
    
                    } else {
                        if(!res.data.error) {
                            setLoading(false)
                            setFlightNotFound(true)
                            updatePages([1])
                        } else {
                            alert('Invalid flight entry! Please try again.')
                            // window.location.reload()
                            console.log(res.data);
                        }
                    }
                } else {
                    setLoading(false)
                    setInvalidFlightEntry(true)
                    updatePages([1])
                }
            }
            )
            .catch((err) => {
                console.log(err)
                setLoading(false)
                setInvalidFlightEntry(true)
                updatePages([1])
         })
    }

    const initializeConnectionBooking = () => {
        getFlightStatus({
            airport: data.airport,
            service: data.service,
            date: data.date,
            flightNumber: data.departureFlightNumber,
        }).then((departureData) => {
            getFlightStatus({
                airport: data.airport,
                service: data.service,
                date: data.date,
                flightNumber: data.arrivalFlightNumber,
            }).then(async(arrivalData) => {

                const arrivalFlights = arrivalData.data.scheduledFlights
                const departureFlights = departureData.data.scheduledFlights
                const arrivalFlight = arrivalFlights.find(flight => flight.arrivalAirportFsCode === data.airport.toUpperCase())
                const departureFlight = departureFlights.find(flight => flight.departureAirportFsCode === data.airport.toUpperCase())

                if(arrivalFlights!==undefined && departureFlight!==undefined) {
                    const airlineName = arrivalData.data.appendix?.airlines.find(airline => airline.fs === arrivalFlight?.carrierFsCode)
                    const conflictingBooking = {...data, airlineName, flightFromDetails: {from: arrivalFlight?.departureAirportFsCode, time: arrivalFlight?.departureTime}, date: arrivalFlight?.arrivalTime, username: userObject.userInfo.email.split('@')[0], departureFlightDetails: departureFlight}
                    const conflictedBooking = checkForFlightBlock(arrivalFlight.arrivalTime)
                    setData({...data, airlineName, flightFromDetails: {from: arrivalFlight?.departureAirportFsCode, time: arrivalFlight?.departureTime}, date: arrivalFlight?.arrivalTime, departureFlightDetails: departureFlight})
    
    
                    if(!checkForBlockedDate(arrivalFlight.arrivalTime) && !checkForBlockedTime(arrivalFlight.arrivalTime) && !checkForFlightBlock(arrivalFlight.arrivalTime)) {
                        if(arrivalFlight.arrivalAirportFsCode === data.airport.toUpperCase()) {
                            const dateMatched = moment(arrivalFlight?.arrivalTime).format('DD') === data.date.day
                            const monthMatched = moment(arrivalFlight?.arrivalTime).format('MM') === (data.date.month)
                            const timeMatched = moment(arrivalFlight?.arrivalTime)
                            const remainingTime = moment.duration(timeMatched.diff(moment())).asHours().toFixed(1)
                
                            // console.log(dateMatched);
                            // console.log(monthMatched);
                            // console.log(remainingTime >= 12);
    
                            if(dateMatched && monthMatched && remainingTime >= 12) {
                                setAvailability(true)
                                setLoading(false)
                            } else {

                                console.log('same day conflict')
                                createReservationSameDay(conflictingBooking).then((conflictLink) => {
                                    handleSendBookingSameDayMessage(conflictingBooking, conflictLink)
                                    .then(() => {
                                        setLoading(false)
                                        setAvailability(false)
                                    })
                                })
                            }
                        } else {
                            setLoading(false)
                            setIncorrectFlightNumber(true)
                            updatePages([1])
                        }
    
                    } else {
                        // this means the it has a booking conflict
                        
                        // handle reservation conflict
                        createReservationConflict(conflictingBooking, conflictedBooking).then((conflictLink) => {
                            handleSendBookingConflictMessage(conflictingBooking, conflictedBooking, conflictLink)
                            .then(() => {
                                console.log('reservation conflict')
                                setLoading(false)
                                setAvailability(false)
                            })
                        })
                    }
                } else {
                    setLoading(false)
                    setIncorrectFlightNumber(true)
                    updatePages([1])
                }
            })
        })
    }
    
    return (
        <>
            <Routes pages={pages} updatePages={updatePages} data={data} setData={setData} authenticated={authenticated} user={user} setUser={setUser} availability={availability} flightNotFound={flightNotFound} isUserActivated={isUserActivated} />
            {loading && <Loading title='Checking for availability'/>}
            <Notification open={flightNotFound} onHide={setFlightNotFound} severity='error' title='Flight Information Not Found'/>
            <Notification open={incorrectFlightNumber} onHide={setIncorrectFlightNumber} severity='error' title='Incorrect Flight Number'/>
            <Notification open={invalidFlightEntry} onHide={setInvalidFlightEntry} severity='error' title='Invalid Flight Entry'/>
        </>
    )
}

export default Pages
