import { useEffect, useState } from 'react'
import styled from 'styled-components'
import '../../animations.css'
import { getConfirmedConflict } from '../../api/auth/handlers'
import AvailabilityPage from './AvailabilityPage'
import ReservationDetailsPage from './ReservationDetailsPage'
import { PageContainer } from './StyledComponents'
import ThankYouPage from './ThankYouPage'

const CompleteBookingPage = ({ user }) => {
	const [data, setData] = useState({})
	const reservationNumber = window.location.pathname?.split('/')[3]
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState('availability')
	
	useEffect(() => {
		setLoading(true)
		getConfirmedConflict(reservationNumber)
		.then((res) => {
			setData(res.data()?.booking?.bookingInfo)
			setLoading(false)
		})
		// eslint-disable-next-line
	}, [])

	return (
			<PageContainer>
				{!loading ?
					<CompleteBookingPageContainer>
						{page === 'availability' && <AvailabilityPage refPage='complete_booking' data={data} setData={setData} updatePages={setPage}/>}
						{page === 'details' && <ReservationDetailsPage refPage='complete_booking' data={data} setData={setData} updatePages={setPage} user={user}/>}
						{page === 'thankYouPage' && <ThankYouPage refPage='complete_booking' reservationNumber={reservationNumber}/>}
					</CompleteBookingPageContainer> :
					null
				}
			</PageContainer>
	)
}

const CompleteBookingPageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;


`

export default CompleteBookingPage