import styled from 'styled-components'


export const Button = (props) => {

	const { type, icon, label } = props
	
	const bgColor = () => {
		let color
		switch(type) {
			case 'success':
				color = '27A745'
				break;
			case 'error':
				color = 'DD3445'
				break;
			default:
				color = '000000'
		}
		return `#${color}`
	}

	return (
		<StyledButton color={`${bgColor}`} {...props}>
			<div className="icon">{icon}</div>
			<div className="label">{label}</div>
		</StyledButton>
	)
}

const StyledButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	width: fit-content;
	color: ${props => props.color ? '#ffffff' : '#000000'};
	background: ${props => props.color};
	border-radius: 5px;
	border: none;
	outline-width: 0;
	cursor: pointer;
	transition: all 250ms;
	
	.icon {
		display: grid;
		place-items: center;
		width: fit-content;
		margin-right: ${props => props.icon ? '10px' : 0};
	}
	
	.label {
		font-size: 1.25rem;
	}

	&:hover {
		opacity: 0.85;
	}
	`