import moment from "moment"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { getConfirmedConflicts, getReservations } from "../../api/auth/handlers"
import arraySort from 'array-sort'
import EditReservationModal from "../modals/EditReservationModal"
import ReactLoading from 'react-loading'


const ReservationPage = ({ user }) => {

	const [reservations, setReservations] = useState([])
	const [selectedReservations, setSelectedReservations] = useState([])
	const [activeTab, setActiveTab] = useState('arrival')
	const [selectedReservation, setSelectedReservation] = useState(null)
	const [loading, setLoading] = useState(false)

	const loadReservations = () => {
		setLoading(true)
		getReservations()
		.then((res) => {
			const r = Object.values(res).map(reservation => Object.values(reservation))
			let array = []
			r.map((b) => {
				return array.push(...b)
			})

			getConfirmedConflicts()
			.then((confirmedConflicts) => {
				array.push(...confirmedConflicts)
			}) .then(() => {
				const sortedDates = arraySort(array, 'bookingInfo.date', { reverse: true })
				setReservations(sortedDates)
				setLoading(false)
			})
		})
	}
	
	useEffect(() => {
		loadReservations()
	}, [activeTab])

	useEffect(() => {
		setSelectedReservations(reservations.filter(reservation => reservation?.bookingInfo?.service === activeTab))
	}, [reservations, activeTab])

	const handleResetTabs = () => {
		document.querySelectorAll(`.tab`).forEach((tab) => {
			tab.classList.remove('active-tab')
		})
	}

	
	const handleSelectTab = (e) => {
		handleResetTabs()
		document.querySelector(`.${e.target.className.split(' ')[3]}`).classList.add('active-tab')
		setActiveTab(e.target.className.split(' ')[3])
	}

	const handleSelectBooking = (reservation) => {
		setSelectedReservation(reservation)
	}

	return (
		<ReservationPageContainer>
			<Tabs>
				<TabItem className='tab arrival active-tab' onClick={handleSelectTab}>Arrivals</TabItem>
				<TabItem className='tab departure' onClick={handleSelectTab}>Departure</TabItem>
				<TabItem className='tab connection' onClick={handleSelectTab}>Connection</TabItem>
			</Tabs>

			{!loading &&
				<ReservationsContainer>
					{selectedReservations.map(({ bookingInfo, userInfo, reservationNumber, isConfirmedConflict }) => (
						bookingInfo?.service === activeTab && userInfo?.email === user.email &&
						<div className='reservation' key={bookingInfo?.date} onClick={() => handleSelectBooking({reservationNumber, bookingInfo, userInfo, isConfirmedConflict: (isConfirmedConflict || false) })}>
							<span className='title'>{`${bookingInfo?.airport} - ${bookingInfo?.service} - ${moment(bookingInfo?.date).format('MMM DD, YYYY')}`}</span>
						</div>
					))}
					{(selectedReservations.length === 0 || selectedReservations.filter(r => r.userInfo?.email === user.email).length === 0) &&
						<NoReservations>No reservations available</NoReservations>
					}
				</ReservationsContainer>
			}

			{loading &&
				<Loader>
						<ReactLoading type='balls' color='#000000'/>
				</Loader>
			}
			<EditReservationModal user={user} reservation={selectedReservation} open={selectedReservation} onHide={setSelectedReservation} reload={loadReservations}/>
		</ReservationPageContainer>
	)
}

export default ReservationPage

const ReservationPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	width: 100%;
	padding: 15px;
`

const Tabs = styled.div`
	margin-top: 15px;
	display: flex;
	width: max-content;
	background: var(--theme);
	border-radius: 99px;
	padding: 15px;
	transition: all 250ms;

	.active-tab {
		background: #ffffff;
		color: #000000;
	}
`

const TabItem = styled.span`
	display: grid;
	place-items: center;
	font-size: 1.5rem;
	font-weight: 600;
	color: #ffffff;
	padding: 5px 15px;
	user-select: none;
	cursor: pointer;
	border-radius: 99px;
	margin: 5px;
	transition: all 250ms;

	@media (max-width: 480px) {
		font-size: 1rem;
	}
`

const ReservationsContainer = styled.div`
	padding: 15px;
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	width: 80%;
	border-radius: 30px;
	background: var(--theme-transparent);

	@media (max-width: 600px) {
		width: 90%;
	}

	.reservation {
		text-transform: uppercase;
		font-size: 1.5rem;
		margin: 5px 0;
		padding: 15px;
		width: 100%;
		background: var(--theme-bg);
		color: #000000;
		border-radius: 99px;
		user-select: none;
		cursor: pointer;

		@media (max-width: 480px) {
		font-size: 1rem;
	}

		&:hover {
			opacity: 0.8;
		}
	}
`

const Loader = styled.div`
    display: grid;
    place-items: center;
    height: 50%;
    width: 100%;
`

const NoReservations = styled.div`
	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 600;
	text-align: center;
`