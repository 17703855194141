import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

const Modal = (props) => {

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, [])

    useEffect(() => {
        if(document.body.clientHeight >= dimensions.height) {
            document.querySelector('.modal-backdrop').style.height = `1500px`
        } else {
            document.querySelector('.modal-backdrop').style.height = '100vh'
        }
    }, [dimensions])

    return (
        <ModalContainer className='modal-backdrop'>
            <ModalChild width={props.width} height={props.height} >
                <CloseModalButton onClick={props.close}><CloseIcon /></CloseModalButton>
                <div className="title">{props.title}</div>
                {props.children}
            </ModalChild>
        </ModalContainer>
    )
}

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: grid;
    place-items: center;
    height: ${document.body.clientHeight >= window.innerHeight ? '1500px' : 'max-content'};
    width: 100%;
    max-height: 100vh !important;
    z-index: 999;
    background: #00000080;
    overflow: hidden;
    `

const ModalChild = styled.div`
    padding: 5px;
    position: relative;
    background: var(--theme-bg);
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    animation: modal 350ms;
    width: ${props => props.width ? props.width : ''};
    overflow-y: scroll;
    overflow-x: hidden;
    
    @media (max-width: 480px) {
        width: 350px;
	}
    
    @media (max-width: 720px) {
        width: 90% !important;
    }
    
    .title {
        width: 100%;
        text-align: center;
        color: var(--theme);
        font-size: 2rem;
        font-weight:600;
        padding: 8px 25px;
        margin: 10px;
        position: relative;
    }
`

const CloseModalButton = styled.div`
    position: absolute;
    display: grid;
    place-items: center;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #ffffff;
    color: var(--blue-dark);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    z-index: 999;
`

export default Modal
