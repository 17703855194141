import { Avatar } from "@mui/material"
import { useEffect, useState } from "react"
import styled from "styled-components"
import ChangePasswordModal from "../modals/ChangePasswordModal"
import EditBillingAddressModal from "../modals/EditBillingAddressModal"
import { PageTitle } from "./StyledComponents"

const SettingsPage = (props) => {

    const { user, isUserActivated } = props
    const [editBillingAddressModal, setEditBillingAddressModal] = useState(false)
    const [changePasswordModal, setChangedPasswordModal] = useState(false)

    const handleSendToPaymentPage = () => {
        window.location = '/payment'
    }

    useEffect(() => {
        const ref = window.location.search.replace('?ref=', '')
        
        if(ref === 'activateAccount' && !isUserActivated) {
            setChangedPasswordModal(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <SettingsPageContainer>
            <PageTitle>User Settings</PageTitle>

            <UserDetails>
                <Avatar className='avatar'>{user.email[0]}</Avatar>
                <div className="username">{user.email.split('@')[0]}</div>
            </UserDetails>

            <SettingsContainer>
                <div className="item" onClick={() => setEditBillingAddressModal(true)}>
                    <div className="item__content">
                        <div className="item__title">📍 Edit Billing Address</div>
                    </div>
                </div>
                <div className="item" onClick={handleSendToPaymentPage}>
                    <div className="item__content">
                        <div className="item__title">💳 Payment Methods</div>
                    </div>
                </div>
                <div className="item" onClick={() => setChangedPasswordModal(true)}>
                    <div className="item__content">
                        <div className="item__title">🔒 Change Password</div>
                    </div>
                </div>
            </SettingsContainer>
            <EditBillingAddressModal user={user} open={editBillingAddressModal} onHide={setEditBillingAddressModal}/>
            <ChangePasswordModal user={user} open={changePasswordModal} onHide={setChangedPasswordModal}/>
        </SettingsPageContainer>
    )
}

const SettingsPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 15px;
    `

const UserDetails = styled.div`
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .avatar {
        width: 130px;
        height: 130px;
        font-size: 3.5rem;
        background: var(--theme);
        text-transform: uppercase;
        cursor: pointer;
    }

    .username {
        font-size: 1.5rem;
        margin-top: 15px;
        font-weight: 800;
        padding: 5px 15px;
        background: #00000008;
        box-shadow: 0 0 0 1px #00000020;
        border-radius: 5px;
        user-select: none;
        cursor: pointer;

        @media (max-width: 480px) {
            font-size: 1.2rem;
        }
    }
`

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 15px;
    user-select: none;
    margin-top: 15px;

    @media (max-width: 500px) {
        width: 95%;
    }
    
    .item {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 15px;
        border: 1px solid var(--theme-dark);
        border-radius: 5px;
        cursor: pointer;
        transition: all 250ms;
        margin: 5px 0;
        overflow: hidden;
        
        &::before {
            display: none;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--theme);
            animation: settings-item 550ms;
        }
        
        &:hover {
            color: #FAFAFA;

            &::before {
                display: block;
            }
        }
        
        .item__content {
            z-index: 999;
            display: flex;
            
            .item__title {
                font-size: 1.2rem;
                font-weight: 600;
                z-index: 2;
            }
        }
        
    }
`

export default SettingsPage