import { signOut } from '@firebase/auth'
import styled from 'styled-components'
import '../app.css'
import { auth } from '../config/firebase'
import { Avatar } from '@mui/material'
import { useState } from 'react'
import SiteLogo from '../images/logo.png'

function Header({setCurrentPage, authenticated, user, appVersion}) {

    const [menu, setMenu] = useState(false)
    const menuStyles = {boxShadow: menu ? '0 0 0 5px rgba(0,0,0,0.15)' : ''}
    
    const handleSignOut = () => {
        signOut(auth)
        .then(() => console.log('signed out'))
    }


    const handleMenu = () => {
        if(menu) {
            document.querySelector('.user-menu')?.focus()

            setTimeout(() => {
                setMenu(false)
            }, 200)
        } else {
            setMenu(true)

            setTimeout(() => {
                document.querySelector('.user-menu')?.focus()
            }, 100)
        }
    }

    return (
        <HeaderComponent>
                <LogoContainer>
                    <Logo src={SiteLogo} alt='site-logo' onClick={() => window.location = '/'}/>
                </LogoContainer>
                <HeaderOptions>
                    {authenticated && user.email.split('@')[0] !== 'admin' &&
                        <UserOptions menu={menu}>
                            <div className="user">
                                <Avatar style={menuStyles} className='avatar' onClick={handleMenu}>{user.email[0]}</Avatar>
                                {menu && 
                                    <UserMenu className='user-menu' tabIndex={0} onBlur={handleMenu}>
                                        <div className="menu-item" onClick={() => window.location = 'reservations'}>Manage Reservations</div>
                                        <div className="menu-item" onClick={() => window.location = 'payment'}>Payment Settings</div>
                                        <div className="menu-item" onClick={() => window.location = 'settings'}>User Settings</div>
                                        <div className="menu-item" onClick={handleSignOut}>Logout</div>
                                    </UserMenu>
                                }
                            </div>
                        </UserOptions>
                    }
                    {authenticated && user.email.split('@')[0] === 'admin' &&
                        <div className="admin-logout" onClick={handleSignOut}>logout</div>
                    }
                    <HeaderVersionContainer>
                        <span>beta</span>
                        <span>-</span>
                        <span>v{appVersion}</span>
                    </HeaderVersionContainer>
                </HeaderOptions>
        </HeaderComponent>
    )
}

const HeaderComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;
    height: 100px;
    width: 100%;
    background: var(--theme-bg);
    backdrop-filter: blur(5px);
    z-index: 999;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

    .admin-logout {
        padding: 5px 15px;
        font-size: 1.5rem;
        font-weight: 600;
        user-select: none;
        background: #000000;
        border-radius: 10px;
        color: #fafafa;
        cursor: pointer;
        transition: all 250ms;

        &:hover {
            background: #383838;
        }
    }
`

const Logo = styled.img`
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    cursor: pointer;

    @media (max-width: 480px) {
        height: 60px;
        padding: 0 20px 0 0;
    }
`

const UserMenu = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: max-content;
    height: fit-content;
    top: calc(100% + 15px);
    right: 0;
    margin: auto;
    background: var(--theme-bg);
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    animation: menu-animation 250ms;
    outline-width: 0;
    box-shadow: 0 0 0 2px var(--theme);
    overflow: hidden;

    @media (max-width:480px) {
        top: 200px;
        bottom: 0;
        left: 0;
        right: 0;
        /* margin: auto; */
        width: 90%;
        height: fit-content;
        border-radius: none;

        .menu-item {
            text-align: center;
            font-size: 1.5rem !important;
        }
    }
    
    .menu-item {
        padding: 8px 25px;
        font-weight: 600;
        font-size: 1.2rem;
        color: var(--theme);
        user-select: none;
        cursor: pointer;

        &:hover {
            background: #E8EBEE;
        }
    }

        .admin-logout {
        font-size: 2rem;
    }
`

const LogoContainer = styled.div`
    flex: 0.8;
`

const HeaderOptions = styled.div`
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    @media (max-width: 480px) {
        flex-direction: column;
    }
`

const UserOptions = styled.div`
    display: flex;
    justify-content: space-between;
    width: fit-content;

    .user {
        position: relative;
        
        @media (max-width: 480px) {
            position: static;
        }
    }
    
    .user > .avatar {
        height: 50px;
        width: 50px;
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 250ms;
        box-shadow: ${props => props.menu ? '0 0 0 5px #E8EBEE;' : ''};
        background: #000000;

        &:hover {
            box-shadow: 0 0 0 5px #E8EBEE;
        }
    }
`

const HeaderVersionContainer = styled.div`
    margin-left: 15px;
    display: flex;
    width: 100px;
    font-size: 1.2rem;

    span {
        margin-left: 2px;
        margin-right: 2px;
    }

    @media (max-width:480px) {
        margin-top: 5px;
    }

`

export default Header