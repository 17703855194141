import { useState, useEffect } from 'react'
import styled from 'styled-components'
import AddUserModal from '../modals/AddUserModal'
import Calendar from 'react-calendar'
import moment from 'moment'
import { getAllBookings, getBlockedDates, getBlockedTimes } from '../../api/auth/handlers'
import UsersModal from '../modals/UsersModal'
import BlockDateTimeModal from '../modals/BlockDateTimeModal'
import { PageTitle } from './StyledComponents'
import ReservationsModal from '../modals/ReservationsModal'
import DateTime from '../../utils/DateTime'
import BlockedDateTimeModal from '../modals/BlockedDateTimeModal'
import ChargesHistoryModal from '../modals/ChargesHistoryModal'
import { Notification } from '../elements/Notification'
import ConflictModal from '../modals/ConflictModal'

const AdminPanel = () => {

    const [addUserModal, setAddUserModal] = useState(false)
    const [usersModal, setUsersModal] = useState(false)
    const [blockDateTimeModal, setBlockDateTimeModal] = useState(false)
    const [blockedDateTimeModal, setBlockedDateTimeModal] = useState(false)
    const [reservationModal, setReservationsModal] = useState(false)
    const [chargesHistoryModal, setChargesHistoryModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState(DateTime.getDate())
    // eslint-disable-next-line
    const [reservations, setReservations] = useState([])
    const [bookedDates, setBookedDates] = useState([])
    
    const [blockedDates, setBlockedDates] = useState([])
    const [blockedTimes, setBlockedTimes] = useState([])

    const [reservationDeleted, setReservationDeleted] = useState(false)
    

    const [conflictModal, setConflictModal] = useState(false)

    
    const getBlockedDateTimes = () => {
        getBlockedDates(setBlockedDates)
        getBlockedTimes(setBlockedTimes)
    }

    const getReservations = () => {
        getAllBookings(setReservations, setBookedDates)
    }

    const reloadReservations = () => {
        getReservations()
    }

    useEffect(() => {
        getBlockedDateTimes()
        getReservations()
    }, [])

    const handleDateChange = (e) => {
        if(bookedDates.find(d => d === moment(e).format("YYYY-MM-DD"))) {
            setSelectedDate(e)
            setReservationsModal(true)
        } else if (blockedDates.find(d => d === moment(e).format("MM-DD-YYYY")) || blockedTimes.find(d => d.date === moment(e).format("MM-DD-YYYY"))) {
            setBlockedDateTimeModal(true)
        } else {
            setSelectedDate(e)
            setReservationsModal(true)
        }
    }

    useEffect(() => {
        if(window.location.pathname === '/admin/conflict') {
            setConflictModal(true)
        }
    }, [])

    // useEffect(() => {
    //     console.log(bookedDates)
    // }, [bookedDates])

    return (
        <AdminPanelContainer>
            <PageTitle>Admin Panel</PageTitle>
            <div className="admin-panel-content">
                <div className="admin-panel-calender">
                    <CalendarContainer>
                        <Calendar
                            className='calender'
                            calendarType='US'
                            defaultValue={selectedDate}
                            // minDate={DateTime.getDate()}
                            value={selectedDate}
                            onChange={handleDateChange}
                            tileClassName={({ date, view }) => {
                                if(bookedDates.find(d => d === moment(date).format("YYYY-MM-DD"))){
                                    return 'highlighted booked'
                                } else if (blockedDates.find(d => d === moment(date).format("MM-DD-YYYY")) || blockedTimes.find(d => d.date === moment(date).format("MM-DD-YYYY"))) {
                                    return 'highlighted blocked'
                                }
                            }} />
                    </CalendarContainer>
                    <button className="unblock-button styled-button" onClick={() => setBlockedDateTimeModal(true)}>Unblock a Date or Time</button>
                    <div className="labels">
                        <div className="item">
                            <div className="color booked" />
                            <div className="label">booked date</div>
                        </div>
                        <div className="item">
                            <div className="color blocked" />
                            <div className="label">blocked date or time</div>
                        </div>
                    </div>
                </div>
                <div className="admin-panel-options">
                    <div className="add-user admin-option-button" onClick={() => setAddUserModal(true)}>
                        Add User
                    </div>
                    <div className="users admin-option-button" onClick={() => setUsersModal(true)}>
                        Users
                    </div>
                    <div className="block-dates admin-option-button" onClick={() => setBlockDateTimeModal(true)}>
                        Block Dates/Time
                    </div>
                    <div className="add-user admin-option-button" onClick={() => setChargesHistoryModal(true)}>
                        Charges History
                    </div>
                </div>
            </div>
            <AddUserModal open={addUserModal} onHide={setAddUserModal}/>
            <UsersModal open={usersModal} onHide={setUsersModal}/>
            <BlockDateTimeModal open={blockDateTimeModal} onHide={setBlockDateTimeModal} getBlockedDateTimes={getBlockedDateTimes}/>
            <BlockedDateTimeModal open={blockedDateTimeModal} onHide={setBlockedDateTimeModal} blockedDates={blockedDates} blockedTimes={blockedTimes} getBlockedDateTimes={getBlockedDateTimes}/>
            <ReservationsModal open={reservationModal} onHide={setReservationsModal} selectedDate={selectedDate} reload={reloadReservations} blockedDates={blockedDates} blockedTimes={blockedTimes} setReservationDeleted={setReservationDeleted}/>
            <ChargesHistoryModal open={chargesHistoryModal} onHide={setChargesHistoryModal}/>
            <ConflictModal open={conflictModal} onHide={setConflictModal}/>
            <Notification open={reservationDeleted} onHide={setReservationDeleted} severity='error' title='Reservation Cancelled Successfully'/>
        </AdminPanelContainer>
    )
}

const AdminPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 15px;

    .admin-panel-content {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 15px;
        margin-top: 25px;
        flex-wrap: wrap;
        flex-grow: .5;
        
        @media (max-width: 780px) {
            flex-direction: column;
        }

        .admin-panel-calender {
            position: relative;
            flex: 0.5;
            margin-right: 10px;
            display: grid;
            place-items: center;
            height: 500px;
            width: 100%;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: #fafafa;

            .unblock-button {
                margin: 10px 0;
                font-size: 1.25rem;
            }

            .labels {
                position: absolute;
                bottom: -50px;
                display: flex;

                .item {
                    display: flex;
                    align-items: center;
                    margin-right: 25px;

                    .color {
                        width: 25px;
                        height: 25px;
                        margin-right: 5px;
                        border-radius: 50%;
                    }
                    
                    .booked {
                        overflow: none !important;
                        background: var(--theme);
                    }
                    .blocked {
                        background: #F50014;
                    }
                }
            }
        }
        
        .admin-panel-options {
            flex: 0.5;
            display: flex;
            margin-left: 10px;
            height: 500px;
            width: 100%;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: #fafafa;
            flex-wrap: wrap;

            @media (max-width: 780px) {
                margin-top: 80px;
            }
            
            .admin-option-button {

                &:nth-child(1) {
                    flex-basis: 45%;
                }

                &:nth-child(2) {
                    flex-basis: 45%;
                }

                flex: 1;
                display: grid;
                place-items: center;
                margin: 5px;
                border-radius: 10px;
                padding: 15px;
                background: var(--theme);
                color: #ffffff;
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;
                user-select: none;
                cursor: pointer;
                transition: all 250ms;

                &:hover {
                    background: var(--theme-dark);
                }

                &:active {
                    transform: scale(0.85);
                }
            }
        }
    }
`

const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 5px;

    .calender {
        border: none;
        border-radius: 15px;
        height: 100%;
        width: 100%;
        padding: 10px;

        .highlighted {
            transition: all 250ms;
            z-index: 999;
            position: relative;
            animation: zoom-in 350ms;
            background: red;
            overflow: visible !important;
            
            abbr {
                display: grid;
                place-items: center;
                border-radius: 50%;
                font-size: 1.5rem;
                line-height: 0;
                width: 100%;
                color: #ffffff;
                font-weight: bold;
                z-index: 99;
                
                @media(max-width: 480px) {
                    font-weight: 800;
                    font-size: 0.8rem;
                }
            }
            
            abbr::before {
                position: absolute;
                content: ' ';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                z-index: -99;

                @media(max-width: 480px) {
                    transform: scale(0.85);
                }
            }

            &:active {
                transform: scale(0.85);
            }

        }

        .booked {
            abbr::before {
                background: var(--theme);
            }
        }

        .blocked {
            abbr::before {
                background: #F50014;
            }
        }
        
        .react-calendar__tile {
            background: #ffffff;
            font-size: 1.2rem;

            &:hover {
                background: #fafafa;
            }
        }

        .react-calendar__tile {
            border-radius: 8px;
            color: #000000;
        }

        button:disabled {
            opacity: 0.5;
            background: #ffffff !important;
            border-radius: 0 !important;
        }

        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next-button,
        .react-calendar__navigation__next2-button {
            font-size:2em;
            font-weight: bold;
        }

        .react-calendar__month-view__weekdays__weekday > abbr {
            text-decoration: none;
            font-size: 0.8rem;
        }

        .react-calendar__navigation__label {
            font-weight: 600;
            font-size: 1.2rem;
            border-radius: 10px;
        }

        /* .react-calendar__tile--active {
            transition: all 250ms;
            background: var(--theme);
            z-index: 999;

            &:hover {
                background: var(--theme-dark);
            }

            abbr {
                padding: 5px;
                border-radius: 50%;
                font-size: 1.8rem;
                color: #ffffff;
                font-weight: bold;

                @media(max-width: 480px) {
                    padding: 2.5px;
                }
            }

            &:active {
                transform: scale(0.85);
            }

            &:focus {
                transform: scale(1.15);
            }
        } */
    }
`

export default AdminPanel