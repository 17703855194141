import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { deleteUser, getUser } from '../../api/auth/handlers'
import { handleDeleteCustomer } from '../../api/stripe/handlers'
import Modal from './Modal'
import ReactLoading from 'react-loading'
import { Loader } from '../pages/StyledComponents'

const DeleteUserConfirmationModal = ({user, open, onHide, goBack, setUserDeleted}) => {

	const username = user?.split('@')[0]

	const [loading, setLoading] = useState(false)
	const [stripeId, setStripeId] = useState(null)
	const [userUid, setUserUid] = useState(null)
	const [deleting, setDeleting] = useState(false)

	useEffect(() => {
		setLoading(true)
		getUser({email: user})
		.then((res) => {
			setStripeId(res.data().stripeId)
			setUserUid(res.data().userInfo.uid)
			setLoading(false)
		})
	}, [user, open])

	const hideModal = () => {
			onHide(!true)
			setUserDeleted(false)
	}

	const handleDeleteUser = () => {
		setDeleting(true)
		handleDeleteCustomer(stripeId)
			.then(() => {
				deleteUser(user)
					.then(() => {
						axios.post(`${process.env.REACT_APP_AUTH_BACKEND}/deleteUser`, {
							uid: userUid
						}).then((res) => {
							setDeleting(false)
							hideModal()
							goBack()
							setUserDeleted(true)
						})
					})
			})
	}

	return (
			open &&
					<Modal title='Delete this user?' close={hideModal}>
							{!deleting ? <DeleteUserConfirmationModalContainer>
								<div className="userDeatils">
									<div className="name">
										{username}
									</div>
								</div>
								<div className="buttons">
									<button className="cancelButton button" onClick={hideModal}>CANCEL</button>
									<button className="deleteButton button" disabled={loading} onClick={handleDeleteUser}>DELETE</button>
								</div>
							</DeleteUserConfirmationModalContainer> :
								<Loader>
									<ReactLoading type='balls' color='#000000'/>
								</Loader>
							}
					</Modal>
	)
}

const DeleteUserConfirmationModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 500px;
	height: max-content;
	
	.userDeatils {
		width: 100%;
		display: flex;
		justify-content: center;

		.name {
			width: fit-content;
			padding: 5px 15px;
			text-align: center;
			font-size: 1.5rem;
			font-weight: 600;
			border-radius: 5px;
			color: var(--theme);
			border: 2px solid var(--theme);
			user-select: none;
		}
	}

	.buttons {
		margin-top: 25px;
		width: 100%;
		height: fit-content;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

		.button {
			margin: 5px;
			float: right;
			padding: 5px 20px;
			height: fit-content;
			background: #FAFAFA;
			border-radius: 5px;
			font-size: 1.05rem;
			font-weight: 600;
			user-select: none;
			outline-width: none;
			border: none;
			cursor: pointer;
			transition: all 250ms;

			&:hover {
				opacity: 0.85;
			}

			&:disabled {
				opacity: 0.5;
			}
		}

		.deleteButton {
			background: #DA3840;
			color: #ffffff;
		}
	}
	
`

export default DeleteUserConfirmationModal
