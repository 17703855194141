import { functions } from "../../config/firebase"
import { httpsCallable } from "firebase/functions"

const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail')
const sendAccountActivationEmail = httpsCallable(functions, 'sendAccountActivationEmail')
const sendReservationBookedEmail = httpsCallable(functions, 'sendReservationBookedEmail')
const sendReservationDeclinedEmail = httpsCallable(functions, 'sendReservationDeclinedEmail')
const sendReservationCancelledEmail = httpsCallable(functions, 'sendReservationCancelledEmail')
const sendBlockedDateTimeConflictMessage = httpsCallable(functions, 'sendBlockedDateTimeConflictMessage')
const sendBookingConflictMessage = httpsCallable(functions, 'sendBookingConflictMessage')
const sendBookingSameDayConflictMessage = httpsCallable(functions, 'sendBookingSameDayConflictMessage')
const sendCompleteBookingEmail = httpsCallable(functions, 'sendCompleteBookingEmail')
const sendReservationCancelledMessage = httpsCallable(functions, 'sendReservationCancelledMessage')


export const handleSendAccountCompletionEmail = async (email, username, password) => {
	const data = {
			email: email,
			first_name: username,
			username: username,
			password: password,
	}

	return await sendWelcomeEmail(data)
	.then(result => {
		console.log(result.data)
	})
	.catch(error => {
		console.error('Error sending account completion email', error)
	})
}

export const handleSendAccountActivationEmail = async (email, username) => {
	const data = {
		email: email,
		first_name: username,
		username: username,
	}

	return await sendAccountActivationEmail(data)
	.then(result => {
		console.log(result.data)
	})
	.catch(error => {
		console.error('Error sending account activation email', error)
	})
}

export const handleSendReservationBookedEmail = async (email, username, bookingObject) => {

	const data = {
		email: email,
		first_name: username,
		reservation_number: bookingObject.reservationNumber,
		passenger_name: bookingObject.passengerName,
		date: bookingObject.bookingInfo.date,
		time: bookingObject.bookingInfo.time,
		service: bookingObject.bookingInfo.service,
		airport: bookingObject.bookingInfo.airport,
		flightNumber: bookingObject.bookingInfo.flightNumber
	}

	return await sendReservationBookedEmail(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending reservation booked email', error)
		})
}

export const handleSendDeclineReservationEmail = async (email, username, bookingObject) => {
	const data = {
			email: email,
			first_name: username,
			date: bookingObject.bookingInfo.date,
			time: bookingObject.bookingInfo.time,
			service: bookingObject.bookingInfo.service,
			airport: bookingObject.bookingInfo.airport,
			flightNumber: bookingObject.bookingInfo.flightNumber
	}

	return await sendReservationDeclinedEmail(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending reservation declined email', error)
		})
}

export const handleSendCancelledReservationEmail = async (email, username, bookingObject) => {
	const data = {
			email: email,
			first_name: username,
			date: bookingObject.bookingInfo.date,
			time: bookingObject.bookingInfo.time,
			service: bookingObject.bookingInfo.service,
			airport: bookingObject.bookingInfo.airport,
			flightNumber: bookingObject.bookingInfo.flightNumber
	}

	return await sendReservationCancelledEmail(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending reservation cancelled email', error)
		})
}

export const handleSendCancelledReservationMessage = async (email, username, bookingObject) => {
	const data = {
		email: email,
		first_name: username,
		date: bookingObject.bookingInfo.date,
		time: bookingObject.bookingInfo.time,
		service: bookingObject.bookingInfo.service,
		airport: bookingObject.bookingInfo.airport,
		flightNumber: bookingObject.bookingInfo.flightNumber
	}

	return await sendReservationCancelledMessage(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending reservation cancelled email', error)
		})
}

export const handleSendBlockedDateOrTimeMessage = async (requestedBooking, conflictLink) => {
	const data = {
		conflictingBooking: requestedBooking,
		conflictLink,
		type: 'CONFLICT',
	}

	return await sendBlockedDateTimeConflictMessage(data).then(result => {
		console.log(result.data)
	})
	.catch(error => {
		console.error('Error sending welcome email', error)
	})
}

export const handleSendBookingConflictMessage = async (conflictingBooking, conflictedBooking, conflictLink) => {
	const data = {
		conflictingBooking,
		conflictedBooking,
		conflictLink,
		type: 'CONFLICT',
	}

	return await sendBookingConflictMessage(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending booking conflict message', error)
		})
}

export const handleSendBookingSameDayMessage = async (booking, conflictLink) => {
	const data = {
		booking,
		conflictLink,
		type: 'SAME-DAY',
	}

	return await sendBookingSameDayConflictMessage(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending same day booking conflict message', error)
		})
}

export const handleSendCompleteBookingEmail = async (email, username, bookingObject) => {
	const data = {
		email,
		first_name: username,
		reservationNumber: bookingObject.reservationNumber,
		complete_booking_link: `https://beta.specialservicesny.com/complete/${username}/${bookingObject.reservationNumber}`,
	}

	return await sendCompleteBookingEmail(data)
		.then(result => {
			console.log(result.data)
		})
		.catch(error => {
			console.error('Error sending complete booking email', error)
		})
}