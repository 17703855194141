import styled from 'styled-components'
import '../../animations.css'
import '../../app.css'
import { useEffect, useState } from 'react'
import { PageContainer, NextButton } from './StyledComponents'
import moment from 'moment'
import { blockFlightTime, bookReservation, generateReservationNumber, getUser } from '../../api/auth/handlers'
import { handleGetCustomer } from '../../api/stripe/handlers'
// import { getPaymentCardImage } from '../../utils/helpers'
// import ChargeUserPage from './ChargeUserPage'
import { handleSendReservationBookedEmail } from '../../api/notification/handlers'
import ThankYouPage from './ThankYouPage'

function ReservationDetailsPage({ refPage, data, setData, updatePages, user }) {


    const { passengerName, passengerPhone, recordLocator, driverName, driverPhone, additionalNotes } = data.passengerDetails

    const [userObject, setUserObject] = useState(null)
    const [reservationNumber, setReservationNumber] = useState(null)
    const [bookingCompleted, setBookingCompleted] = useState(false)
    // const [stripeCustomer, setStripeCustomer] = useState(null)
    // const [defaultPaymentSourceId, setDefaultPaymentSourceId] = useState(null)
    // const [defaultCard, setDefaultCard] = useState(null)
    // const [userPrice, setUserPrice] = useState(null)
    // const [chargeInitiated, setChargeInitiated] = useState(false)

    const handleCompleteReservation = () => {

        // prepare booking object
        const bookingObject = {
            passengerName: passengerName,
            reservationNumber: reservationNumber,
            bookingInfo: data,
            userInfo: {
                uid: user.uid,
                email: user.email,
            },
        }

        bookReservation(user, data, reservationNumber)
        .then(() => {
            blockFlightTime(data.airport, data.service, data.date, bookingObject)
            .then(() => {
                handleSendReservationBookedEmail(userObject.email, user.email.split('@')[0], bookingObject)
                .then(() => {
                    setBookingCompleted(true)
                })
            })
        })

        // setChargeInitiated(true)
    }

    useEffect(() => {
        generateReservationNumber(data.service)
        .then((number) => {
            setReservationNumber(number)
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // const service = `${data.airport.toLowerCase()}${capitalize(data.service.slice(0, 3))}`
        getUser(user)
        .then(userObject => {    
            setUserObject(userObject.data())
            // setUserPrice(userObject.data().prices[service])
            // setStripeCustomer(userObject.data()?.stripeId)        
            handleGetCustomer(userObject.data()?.stripeId)
            // .then((res) => {
            //     setDefaultPaymentSourceId(res.data.default_source)
            // })
        })
        // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //     if(stripeCustomer !== null && defaultPaymentSourceId !== null) {
    //         handleGetPaymentCard(stripeCustomer, defaultPaymentSourceId).then((res) => {
    //             setDefaultCard(res.data)
    //         })
    //     }
    // }, [stripeCustomer, defaultPaymentSourceId])

    // const serviceItems =
    //     [
    //         {
    //             label: 'Airport',
    //             value: data.airport,
    //         },
    //         {
    //             label: 'Service',
    //             value: data.service,
    //         },
    //         {
    //             label: 'Date',
    //             value: moment(data.date).format('ddd, MMMM Do YYYY'),
    //         },
    //         {
    //             label: 'Time',
    //             value: moment(data.date).format('hh:mm A'),
    //         },
    //         {
    //             label: 'Flight No',
    //             value: data.flightNumber.toUpperCase(),
    //         },
    //         {
    //             label: 'Passenger',
    //             value: passengerName,
    //         },
    //         {
    //             label: 'Passenger Phone',
    //             value: passengerPhone,
    //         },
    //         {
    //             label: 'PNR',
    //             value: recordLocator,
    //         },
    //         {
    //             label: 'Driver Name',
    //             value: driverName,
    //         },
    //         {
    //             label: 'Driver Phone',
    //             value: driverPhone,
    //         },
    //         {
    //             label: 'Notes',
    //             value: additionalNotes,
    //         },
    //     ]

        // const chargeItems = 
        // [
        //     {
        //         label: `${data.service} Fee`,
        //         value: `$${userPrice}`
        //     },
        //     {
        //         label: 'Additional Hours',
        //         value: ''
        //     },
        //     {
        //         label: 'Early Morning/Late Night Surcharge',
        //         value: ''
        //     },
        //     {
        //         label: 'Total',
        //         value: ''
        //     },
        // ]

    return (
        <PageContainer>
            {!bookingCompleted ? 
                <ReservationDetailsContainer>
                    <div className="container">
                        <div className="title-container">
                            <div className='title'>Reservation Details</div>
                            <div className="reservation-number-row container-row">
                                <div className="label">
                                    <span>Your reservation number is:</span>
                                    <span className="reservation-number">{reservationNumber ? reservationNumber : '...'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flight-data-container">
                            <div className="flight-data-col">
                                <div className="airline-number">
                                    <span className="airline-name">{data?.airlineName?.name}</span>
                                    <span className="flight-number">{data?.flightNumber?.toUpperCase()}</span>
                                </div>
                                <img className="flight-icon" src={require('../../images/flight-icon.png')} alt="flight icon" />
                            </div>
                            <span className='separator-line'/>
                            <div className="flight-data-col">
                                <div className="flight-details">
                                    <div className="service-type">
                                        <span>{data.service.toUpperCase()}</span>
                                        <span className="city-separator-line" />
                                        <span className="city">{data.service === 'arrival' ? 'to' : 'from'} New York</span>
                                    </div>
                                    <div className="destination">
                                        <span className="airport">{data.airport}</span>
                                        <span className="time-terminal">
                                            <span className="time-date">{moment(data.date).format('hh:mm a')} - {moment(data.date).format('MM/DD/YYYY')}</span>
                                            <span className="terminal">Terminal {data.terminal}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <span className='separator-line'/>
                            <div className="flight-data-col">
                                <div className='passenger-details'>
                                    <span>Passenger Name: <b>{passengerName}</b></span>
                                    <span>Passenger Phone: <b>{passengerPhone}</b></span>
                                    <span>PNR: <b>{recordLocator}</b></span>
                                </div>
                            </div>
                            <span className='separator-line'/>
                            <div className="flight-data-col">
                                <div className='driver-details'>
                                    <span>Driver Name: <b>{driverName}</b></span>
                                    <span>Driver Phone: <b>{driverPhone}</b></span>
                                    <span>Addional Notes: <b>{additionalNotes}</b></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row-items service-items">
                            <div className="row-title">Charge Items:</div>
                            {chargeItems.map(item => (
                                <div className="row-item charge-item">
                                    <div className="label">{item.label}:</div>
                                    <div className="value">{item.value}</div>
                                </div>
                            ))}
                        </div>
                        <div className="row-items credit-card-row">
                            <span className='row-title'>Payment Method:</span>
                            <span className='card-info'>
                                <span className='brand-icon'>{getPaymentCardImage(defaultCard?.brand)}</span>
                                <span className="number">****{defaultCard?.last4}</span>
                                <span className="expiry">{defaultCard?.exp_month}/{defaultCard?.exp_year?.toString().substr(-2)}</span>
                            </span>
                        </div> */}
                    </div>
                    <span className="reservationNote">Please click on <b>Complete Reservation</b> button to book this reservation.</span>
                    <NextButton onClick={handleCompleteReservation}>Complete Reservation</NextButton>
                    </ReservationDetailsContainer> :
                <ThankYouPage />
                // <ChargeUserPage user={user} userData={data} reservationNumber={reservationNumber} customerId={stripeCustomer} cardId={defaultCard.id} amount={userPrice} description={`SSNY CHARGE for ${data.airport} ${data.service}`} updatePages={updatePages}/>
            }
        </PageContainer>
    )
}

const ReservationDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 480px;
    height: 100%;
    margin: 8px 0;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    animation: zoom-in 350ms;
    background: #F6F6F6;
    overflow: hidden;

    @media (max-width: 480px) {
        width: 95% !important;
    }

    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 200px;
        background-color: var(--theme);
    }
    
    & .title {
        position: relative;
        top: 0;
        text-align: center;
        padding: 8px 0;
        font-size: 1.6rem;
        width: 100%;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        border-radius: 15px 15px 0 0;
        user-select: none;
    }
    
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* overflow-y: scroll; */
        margin-bottom: 15px;
        height: 100%;

        &::-webkit-scrollbar {
        display: flex;
        width: 5px;
        margin: 20px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--theme-transparent);
            border-radius: 5px;
            
            &:hover {
                background: var(--theme)
            }
        }
        
        .container-row {
            width: 100%;
            justify-content: center;
            text-align: center;
            margin: 10px 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .reservation-number-row {
            padding-top: 50px;

            .label {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 1.8rem;
            
                .reservation-number {
                    margin-left: 5px;
                    padding: 5px 10px;
                    color: #ffffff;
                    font-size: 1.8rem;
                    font-weight: 600;
                    border-radius: 8px;
                }

                .copy-button,
                .copied-icon {
                    color: var(--theme-dark);
                    margin: 5px;
                    cursor: pointer;
                    animation: zoom-in 250ms;
                }

                @media (max-width: 480px) {
                    flex-direction: column;

                    span {
                        margin: 5px 0;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .row-title {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .row-items {
            display: flex;
            flex-direction: column;
            width: 80%;
            align-self: center;
            text-align: left;
            text-transform: capitalize;
            margin-top: 20px;
            margin-bottom: 20px;

            .row-item {
                margin-top: 5px;
                margin-bottom: 5px;
                margin-top: 6px;
                margin-bottom: 6px;
                width: 100%;
                display: flex;
                font-size: 1.05rem;

                @media(max-width: 480px) {
                    justify-content: space-between;
                    font-size: 1rem;
                }

                .label {
                    width: 45%;
                    font-size: 1.15rem;
                    font-weight: bold;
                    justify-content: center;
                }
                
                .value {
                    font-size: 1.15rem;
                    font-weight: 500;
                    @media(max-width: 480px) {
                        text-align: right;
                    }
                }     
            }

            .charge-item {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .credit-card-row {
            margin-top: 25px;
            width: 80%;
            align-self: center;

            .card-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 65%;
                font-weight:  600;
                margin: 15px 0;
                margin-top: 15px;
                margin-bottom: 15px;

                .brand-icon {
                    height: 65px;
                    width: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2398D410;
                    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        object-fit: contain;
                    }
                }

                .number,
                .expiry {
                    background: #2398D410;
                    padding: 5px 15px;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                }

                @media (max-width: 480px) {
                    flex-direction: column;
                    width: 100%;

                    .brand-icon,
                    .number,
                    .expiry {
                        margin: 8px 0;
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }
                }
            }
        }

        .flight-data-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 15px 35px;
            
            .flight-data-col {
                display: flex;
                align-items: center;
                width: 100%;
                
                .airline-number {
                    display: flex;
                    flex-direction: column;
                    font-size: 35px;
                }

                .flight-icon {
                    margin-left: auto;
                    height: 35px;
                    width: 66px;
                }

                .flight-details {
                    display: flex;
                    flex-direction: column;

                    .service-type {
                        margin: 5px 0;
                        display: flex;
                        align-items: center;
                        font-size: 35px;
                        color: #000000;
                        opacity: 0.5;
                        
                        .city-separator-line {
                            margin: 0 10px;
                            width: 30px;
                            height: 2px;
                            background-color: #000000;
                        }

                        .city {
                            font-size: 20px;
                        }
                    }

                    .destination {
                        margin: 5px 0;
                        display: flex;
                        align-items: center;
                        color: #000000;
                        
                        .airport {
                            font-size: 35px;
                        }

                        .time-terminal {
                            margin-left: 8px;
                            display: flex;
                            flex-direction: column;

                            .time-date {
                                font-size: 17px;
                            }

                            .terminal {
                                opacity: 0.5;
                            }

                        }
                    }

                }

                .passenger-details, .driver-details {
                    display: flex;
                    flex-direction: column;

                    span {
                        margin: 1.5px 0;
                    }
                }

            }
        
        }

    }

    .reservationNote {
        font-weight: 500;
        font-size: 14px;
        width: 80%;
        margin-bottom: 15px;
    }

    .separator-line {
        margin: 15px 0;
        height: 2px;
        width: 100%;
        background-color: #000000;
        opacity: 0.5;
    }
`

export default ReservationDetailsPage