import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Loading from './components/elements/Loading';
import ActivateAccount from './components/pages/ActivateAccount';
import AdminPanel from './components/pages/AdminPanel';
import CompleteBookingPage from './components/pages/CompleteBookingPage';
import LoginPage from './components/pages/LoginPage';
import PaymentPage from './components/pages/PaymentPage';
import ReservationPages from './components/pages/ReservationPages';
import ReservationPage from './components/pages/ReservationsPage';
import SettingsPage from './components/pages/SettingsPage';

const Routes = (props) => {

  const { authenticated, setUser } = props

  return (
    <>
      {!authenticated && window.location.pathname === '/' && <Loading title='Checking in'/>}
      {authenticated ? <AuthenticatedRoutes {...props} /> : <UnAuthenticatedRoutes setUser={setUser}/>}
    </>
  )
}

const AuthenticatedRoutes = (props) => {
  return (
    <Router>
      <Route exact path="/">
        {props.isUserActivated === true ? <ReservationPages {...props} /> : <ActivateAccount user={props.user} isUserActivated={props.isUserActivated}/>}
      </Route>
      <Route exact path="/reservations"><ReservationPage user={props.user} isUserActivated={props.isUserActivated}/></Route>
      <Route exact path="/payment"><PaymentPage user={props.user} isUserActivated={props.isUserActivated}/></Route>
      <Route exact path="/settings"><SettingsPage user={props.user} isUserActivated={props.isUserActivated}/></Route>
      <Route exact path="/complete/:user/:reservationNumber"><CompleteBookingPage user={props.user} isUserActivated={props.isUserActivated}/></Route>
      {props.user && props.user.email.replace('@specialservicesny.com', '') === 'admin' && <Route exact path="/admin" component={AdminPanel}/> }
      {props.user && props.user.email.replace('@specialservicesny.com', '') === 'admin' && <Route exact path="/admin/conflict" component={AdminPanel}/> }
    </Router>
  )
}

const UnAuthenticatedRoutes = ({setUser}) => {
  return (
    <Router>
      <Route exact path="/login"><LoginPage setUser={setUser} /></Route>
    </Router>
  )
}

export default Routes;