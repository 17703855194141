import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { getAllUsers, getChargesHistory } from '../../api/auth/handlers'
import ReactLoading from 'react-loading'
import { Loader } from '../pages/StyledComponents'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const ChargesHistoryModal = ({open, onHide}) => {

    const [modalTitle, setModalTitle] = useState('Select user to view charges')
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
		const [chargesHistory, setChargesHistory] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
				setLoading(true)
        getAllUsers().then(res => {
					setUsers(res)
					setLoading(false)
        })
    }, [open, selectedUser])

    const hideModal = () => {
			onHide(!true)
			setSelectedUser(null)
    }

    const handleSelectUser = (user) => {
				setChargesHistory([])
				setLoading(true)
				setModalTitle(user.email.replace('@specialservicesny.com', ''))
				setSelectedUser(user.email)
				getChargesHistory(user).then(res => {
					setChargesHistory([Object.values(res.data())][0])
					setLoading(false)
				})

    }

    const goBack = () => {
        setSelectedUser(null)
        setModalTitle('Select user to view charges')
    }


    return (
        open &&
            <>
							<Modal title={modalTitle} close={hideModal}>
									{!selectedUser ?
											<UsersModalContainer>
													{users.length > 1 ? users.map(user => (
															user.userInfo.email.split('@')[0] !== 'admin' &&
															<div key={user.userInfo.uid} className="user" onClick={() => handleSelectUser(user.userInfo)}>
																	<div className="username">{user.userInfo.email.replace('@specialservicesny.com', '')}</div>
															</div>
													)) :
													users.length === 0 ?
													<Loader>
															<ReactLoading type='balls' color='#000000'/>
													</Loader> :
													<NoUsersFound>no users found</NoUsersFound>
													}
											</UsersModalContainer> :
											<UserDetailsContainer>
													<GoBackToUsers onClick={(goBack)}>
															<ArrowBackIcon className='goBackArrow'/>
													</GoBackToUsers>
													{!loading ?
														chargesHistory.length > 0 ?
															<ChargesHistoryContainer>
																{chargesHistory.map(history => (
																	<div className="row">
																		<div className="col">
																			<div className="label">Airport</div>
																			<div className="value">{history.airport}</div>
																		</div>
																		<div className="col">
																			<div className="label">Service</div>
																			<div className="value">{history.service}</div>
																		</div>
																		<div className="col">
																			<div className="label">Flight #</div>
																			<div className="value">{history.flight}</div>
																		</div>
																		<div className="col">
																			<div className="label">Date</div>
																			<div className="value">{history.date}</div>
																		</div>
																		<div className="col">
																			<div className="label">Charge</div>
																			<div className="value">{'$'}{history.charge}</div>
																		</div>
																	</div>
																))}
															</ChargesHistoryContainer> :
																<NotFound>
																	No charges found
																</NotFound> :
																<Loader>
																	<ReactLoading type='balls' color='#000000'/>
																</Loader>
													}

											</UserDetailsContainer>
                    }
                </Modal>
            </>
    )
}

const UsersModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    height: 300px;
    overflow-y: scroll;
    position: relative;

		@media (max-width: 600px) {
        width: 100%;
    }

    .user {
        margin: 5px;
        width: 90%;
        padding: 20px 25px;
        background: #fafafa;
        border: 1px solid var(--theme);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        transition: all 350ms;

        .username {
            font-weight: 600;
            font-size: 1.5rem;
        }

        &:hover {
            opacity: 0.5;
        }
    }
`

const UserDetailsContainer = styled.div`
    width: 600px;
    height: 500px;
    padding: 15px;
    overflow: hidden;
    overflow-y: scroll;

		@media (max-width: 600px) {
        width: 100%;
    }

    &::-webkit-scrollbar {
        display: flex;
        width: 5px;
        margin: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--theme-transparent);
        border-radius: 5px;

        &:hover {
            background: var(--theme)
        }
    }

    .title {
        margin: auto;
        width: fit-content;
        font-size: 1.5rem;
        font-weight: bold;
        padding-bottom: 5px;
        border-bottom: 3px solid var(--theme);
        margin-bottom: 25px;
    }
`

const ChargesHistoryContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	
	.row {
		border-radius: 10px;
		padding: 15px 25px;
		margin: 5px 0;
		display: flex;
		font-size: 1rem;
		justify-content: space-between;
		background: #fafafa;
		
		.col {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: auto;
		}

		.label {
			font-weight: 500;
		}
		
		.value {
			margin-top: 5px;
			font-weight: 400;
			border-radius: 5px;
			text-transform: uppercase;
		}
			}
`

const GoBackToUsers = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    padding: 15px;
    background: var(--theme);
    border-radius: 15px 0 15px 0;
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }

    .goBackArrow {
        font-size: 2rem;
        color: #ffffff;
    }
`

const NotFound = styled.div`
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	font-size: 1rem;
	font-weight: 800;
`

const NoUsersFound = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
`

export default ChargesHistoryModal
