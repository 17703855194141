import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import ReactLoading from 'react-loading'
import { blockFlightTime, cancelReservation, getReservation, getUser, updateConfirmedConflictReservation, updateReservation } from '../../api/auth/handlers'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '../elements/Button'
import { handleSendCancelledReservationEmail, handleSendCancelledReservationMessage } from '../../api/notification/handlers'

const EditReservationModal = ({user, reservation, open, onHide, reload}) => {

	const [loading, setLoading] = useState(false)
	const [passengerName, setPassengerName] = useState('')
	const [driversName, setDriversName] = useState('')
	const [driversPhone, setDriversPhone] = useState('')
	const [recordLocator, setRecordLocator] = useState('')
	const [additionalNotes, setAdditionalNotes] = useState('')
	const [userObject, setUserObject] = useState(null)

	const hideModal = () => {
		setLoading(false)
		onHide(null)
		reload()
	}

	useEffect(() => {
		getUser(user)
			.then(userObject => {    
				setUserObject(userObject.data())
			})
			// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(reservation) {
			setPassengerName(reservation?.bookingInfo?.passengerDetails?.passengerName || '')
			setDriversName(reservation?.bookingInfo?.passengerDetails?.driverName || '')
			setDriversPhone(reservation?.bookingInfo?.passengerDetails?.driverPhone || '')
			setRecordLocator(reservation?.bookingInfo?.passengerDetails?.recordLocator || '')
			setAdditionalNotes(reservation?.bookingInfo?.passengerDetails?.additionalNotes || '')
		}
	}, [reservation])

	const handleUpdateReservation = () => {
		setLoading(true)
		const service = `${reservation.bookingInfo?.service}s`
		const reservationId = reservation.reservationNumber

		if(!reservation.isConfirmedConflict) {
			getReservation(service, reservationId)
			.then((reservationObject) => {
					const id = reservationObject[0]
					const reservation = reservationObject[1]
					const updatedReservation = {}
					updatedReservation[id] = {
						...reservation,
						bookingInfo: {
							...reservation.bookingInfo,
							passengerDetails: {
								passengerName,
								driverName: driversName,
								driverPhone: driversPhone,
								recordLocator,
								additionalNotes,
							}
						}
					}
	
					const updatedFlightBlock = {
						bookingInfo: {
							...reservation.bookingInfo,
							passengerDetails: {
								passengerName,
								driverName: driversName,
								driverPhone: driversPhone,
								recordLocator,
								additionalNotes,
							}
						}
					}
	
					blockFlightTime(reservation.bookingInfo.airport, reservation.bookingInfo.service, reservation.bookingInfo.date, {...reservation, ...updatedFlightBlock})
						.then(() => {
							updateReservation(service, updatedReservation)
							.then(() => {
								setLoading(false)
								hideModal()
							})
						})
					})
					.catch((err) => {
						console.log(err.message)
					})
				} else {

					const updatedReservation =  {
						booking: {
							bookingInfo: {
								...reservation.bookingInfo,
								passengerDetails: {
									passengerName,
									driverName: driversName,
									driverPhone: driversPhone,
									recordLocator,
									additionalNotes,
								}
							}
						}
					}

					updateConfirmedConflictReservation(reservationId, updatedReservation)
					.then(() => {
						setLoading(false)
						hideModal()
					})
		}
	}

	const handleCancelReservation = () => {
		setLoading(true)
		cancelReservation((reservation?.bookingInfo?.service).toLowerCase(), reservation)
		.then((res) => {
			if(res === 'success') {
				handleSendCancelledReservationMessage(userObject.email, user.email.split('@')[0], { bookingInfo: reservation.bookingInfo })
				.then(() => {
					handleSendCancelledReservationEmail(userObject.email, user.email.split('@')[0], { bookingInfo: reservation.bookingInfo })
					.then(() => {
						setLoading(false)
						hideModal()
					})
				})
			}
		})
	}

	return (
		open &&
			<Modal title='Edit Reservation' close={hideModal}>
				<EditReservationModalContainer>
					{!loading &&
						<>
							<FieldContainer>
								<FieldName>Passenger Name</FieldName>
								<Field placeholder='Passenger Name' value={passengerName} onChange={e => setPassengerName(e.target.value)} />
							</FieldContainer>
							<FieldContainer>
								<FieldName>Driver's Name</FieldName>
								<Field placeholder="Driver's Name" value={driversName} onChange={e => setDriversName(e.target.value)}  />
							</FieldContainer>
							<FieldContainer>
								<FieldName>Driver's Phone</FieldName>
								<Field placeholder="Driver's Phone" value={driversPhone} onChange={e => setDriversPhone(e.target.value)}  />
							</FieldContainer>
							<FieldContainer>
								<FieldName>Record Locator</FieldName>
								<Field placeholder="Record Locator" value={recordLocator} onChange={e => setRecordLocator(e.target.value)}  />
							</FieldContainer>
							<FieldContainer>
								<FieldName>Additional Notes</FieldName>
								<Field placeholder="Comments" value={additionalNotes} onChange={e => setAdditionalNotes(e.target.value)}  />
							</FieldContainer>
							<ModifyMessage>To modify the date or time of your reservation, please cancel and rebook.</ModifyMessage>
							<Button style={{ margin: '5px 0', alignSelf: 'center' }} icon={<CancelIcon />} label='Cancel Reservation' color='#C93839' type='error' onClick={handleCancelReservation}/>
							<Button style={{ margin: '5px 0', alignSelf: 'center' }} onClick={handleUpdateReservation} label='Save' color='#000000' />
						</>
					}

					{loading &&
						<Loader>
								<ReactLoading type='balls' color='#000000'/>
						</Loader>
					}
				</EditReservationModalContainer>
			</Modal>
	)
}

const EditReservationModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 15px;
	width: 450px;
  height: 100%;

	@media (max-width: 600px) {
		width: 100%;
	}
`

const Loader = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
`

// const Button = styled.div`
//     background: var(--theme);
//     padding: 8px 0;
//     border-radius: 10px;
//     font-size: 1.5rem;
//     font-weight: 600;
//     text-align: center;
//     color: #fafafa;
//     text-transform: uppercase;
//     margin: 5px;
//     user-select: none;
//     cursor: pointer;
// `

const FieldContainer = styled.div`
	margin: 5px 0;
	display: flex;
	flex-direction: column;
	`

const FieldName = styled.span`
	width: fit-content;
	background: black;
	color: white;
	padding: 5px 15px;
`

const Field = styled.input`
	width: 100%;
	padding: 8px 15px;
	outline-width: 0;
	background: transparent;
	border: 2px solid var(--theme-dark);
	font-size: 1.5rem;
`

const ModifyMessage = styled.span`
	color: var(--theme);
	font-size: 1rem;
	margin: 5px 0;
`

export default EditReservationModal