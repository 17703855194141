import styled from "styled-components"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const TimePicker = ({timePickerTitle, hour, setHour, minute, setMinute, period = 'PM', setPeriod}) => {

    const handleTimeChange = (unit, value) => {
        if(unit === 'hour' && value <= 12) {
            setHour(value)
        }
        else if(unit === 'minute' && value <= 59) {
            setMinute(value)
        }
    }

    const handleTimeSet = (mode, unit, behavior, event) => {
        if (behavior === 'button') {
            if(mode === 'increase') {
                if(unit === 'hour') {
                    if(hour !== 12) {
                        setHour(Number(hour)+1)
                    } else {
                        setHour(1)
                    }
                } else {
                    if(minute !== 59) {
                        setMinute(Number(minute)+1)
                    } else {
                        setMinute(0)
                    }
                }
            } else if (mode === 'decrease') {
                if(unit === 'hour') {
                    if(hour !== 1 && hour !== '') {
                        setHour(Number(hour)-1)
                    } else {
                        setHour(12)
                    }
                } else {
                    if(minute !== 0 && minute !== '') {
                        setMinute(Number(minute)-1)
                    } else {
                        setMinute(59)
                    }
                }
            }
        } else if (behavior === 'scroll') {
            if (event.nativeEvent.wheelDelta > 0) {
                if(unit === 'hour') {
                    if(hour !== 1) {
                        setHour(Number(hour)-1)
                    } else {
                        setHour(12)
                    }
                } else {
                    if(minute !== 0) {
                        setMinute(Number(minute)-1)
                    } else {
                        setMinute(59)
                    }
                }
              } else {
                if(unit === 'hour') {
                    if(hour !== 12) {
                        setHour(Number(hour)+1)
                    } else {
                        setHour(1)
                    }
                } else {
                    if(minute !== 59) {
                        setMinute(Number(minute)+1)
                    } else {
                        setMinute(0)
                    }
                }
            }
        }
    }

    return (
        <TimePickerContainer>
            <div className="time_picker_title">{timePickerTitle}</div>
            <div className='time_picker'>
                <div className="time_container hour_container">
                    <div className="increase_button" onClick={() => handleTimeSet('increase', 'hour', 'button')}><KeyboardArrowUpIcon /></div>
                    <input type="number" className="time hour" maxLength={2} value={hour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} onChange={(e) => handleTimeChange('hour', e.target.value)} onWheel={(e) => handleTimeSet(null, 'hour', 'scroll', e)} />
                    <div className="decrease_button" onClick={() => handleTimeSet('decrease', 'hour', 'button')}><KeyboardArrowDownIcon /></div>
                </div>
                <div className="time_divider">
                    :
                </div>
                <div className="time_container minute_container">
                    <div className="increase_button" onClick={() => handleTimeSet('increase', 'minute', 'button')}><KeyboardArrowUpIcon /></div>
                    <input type="number" className="time hour" maxLength={2} value={minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} onChange={(e) => handleTimeChange('minute', e.target.value)} onWheel={(e) => handleTimeSet(null, 'minute', 'scroll', e)} />
                    <div className="decrease_button" onClick={() => handleTimeSet('decrease', 'minute', 'button')}><KeyboardArrowDownIcon /></div>
                </div>
                <div className="period_container">
                    <div className="period" onClick={() => setPeriod('AM')}>
                        <input type="radio" className="switch" checked={period === 'AM'} onChange={() => setPeriod('AM')}/>
                        <div className="name">AM</div>
                    </div>
                    <div className="period" onClick={() => setPeriod('PM')}>
                        <input type="radio" className="switch" checked={period === 'PM'} onChange={() => setPeriod('PM')} />
                        <div className="name">PM</div>
                    </div>
                </div>
            </div>
        </TimePickerContainer>
    )
}

const TimePickerContainer = styled.div`
    height: 100%;
    width: 100%;

    @media (max-width: 600px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .time_picker_title {
        margin-bottom: 15px;
        width: 200px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--theme);

        @media (max-width: 600px) {
            width: 80%;
            text-align: center;
        }
    }

    .time_picker {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .time_container {
        border: 1px solid #D3D3D3;

        .increase_button,
        .decrease_button {
            display: flex;
            justify-content: center;
            width: 100%;
            cursor: pointer;

            &:active {
                box-shadow: 0 0 0 2px var(--theme-transparent);
            }
        }

        .increase_button {
            border-bottom: 1px solid #D3D3D3;
        }

        .decrease_button {
            border-top: 1px solid #D3D3D3;
        }


        .time {
            padding: 8px;
            width: 4rem;
            border: none;
            outline-width: 0;
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
            margin: 2px 0;

            &:focus {
                box-shadow: 0 0 0 2px var(--theme-transparent);
            }
        }
    }

    .time_divider {
        padding: 0 15px;
        font-weight: bold;
    }

    .period_container {
        .period {
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            .switch {
                cursor: pointer;

                @media (max-width: 600px) {
                    transform: scale(1.5);
                }
            }

            .name {
                margin-left: 5px;
                color: var(--theme);
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;
                user-select: none;

                @media (max-width: 600px) {
                    font-size: 1.5rem;
                    padding-left: 5px;
                }
            }
        }
    }
`

export default TimePicker