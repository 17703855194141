import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getFunctions } from 'firebase/functions'

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "special-services-ny.firebaseapp.com",
  projectId: "special-services-ny",
  storageBucket: "special-services-ny.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})

const db = getFirestore()

const functions = getFunctions()

const auth = getAuth()

export { auth, db, functions }