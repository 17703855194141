import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { addToConfirmedConflicts, generateReservationNumber, getReservationConflict, getUser, removeFromReservationConflict } from '../../api/auth/handlers'
import Modal from './Modal'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import moment from 'moment'
import { Button } from '../elements/Button'
import ReactLoading from 'react-loading'
import { Loader } from '../pages/StyledComponents'
import { handleSendCompleteBookingEmail, handleSendDeclineReservationEmail } from '../../api/notification/handlers'

const ConflictModal = ({open, onHide}) => {

	const conflictId = new URLSearchParams(window.location.search).get('id')
	const [conflictData, setConflictData] = useState(null)
	const [bookingData, setBookingData] = useState(null)
	const [conflictType, setConflictType] = useState(null)

	const [user, setUser] = useState(null)
	const [userObject, setUserObject] = useState(null)
	const [reservationNumber, setReservationNumber] = useState(null)

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if(open) {
			setLoading(true)
			getReservationConflict(conflictId)
			.then((res) => {
				const data = res.data()

				setConflictType(data.type)

				if (data.type === "CONFLICT") {
					setConflictData(data)
				
					// generate reservation number for the conflicting booking
					generateReservationNumber(data.conflictingBooking.service)
					.then((number) => {
						setReservationNumber(number)
					})
					
					getUser({email: `${data.conflictingBooking.username}@specialservicesny.com`})
					.then((userObject) => {
						setUserObject(userObject.data())
						setUser(userObject.data().userInfo)
						setLoading(false)
					})
				} else if (data.type === 'SAME-DAY' || data.type === 'DATE-TIME') {
					setBookingData(data)
				
					// generate reservation number for the conflicting booking
					generateReservationNumber(data.booking.service)
					.then((number) => {
						setReservationNumber(number)
					})
					
					getUser({email: `${data.booking.username}@specialservicesny.com`})
					.then((userObject) => {
						setUserObject(userObject.data())
						setUser(userObject.data().userInfo)
						setLoading(false)
					})
				}
			})
		}
			// eslint-disable-next-line
	}, [open])

	const hideModal = () => {
		onHide(!true)
	}

	const handleConfirmReservation = () => {
		const booking = conflictType === 'CONFLICT' ? conflictData.conflictingBooking : conflictType === 'SAME-DAY' ? bookingData.booking : bookingData.booking


		// prepare booking object
		const bookingObject = {
			reservationNumber,
			bookingInfo: booking,
			userInfo: {
					uid: user.uid,
					email: user.email,
			}
		}

		setLoading(true)
		handleSendCompleteBookingEmail(userObject.email, user.email.split('@')[0], bookingObject)
		.then(() => {
			addToConfirmedConflicts(userObject.email, user.email.split('@')[0], bookingObject)
			.then(() => {
				removeFromReservationConflict(conflictId)
				.then(() => {
					setLoading(false)
					hideModal()
					window.location = '/'
				})
			})
		})
	}
	
	const handleDeclineReservation = () => {
		const booking = conflictType === 'CONFLICT' ? conflictData.conflictingBooking : conflictType === 'SAME-DAY' ? bookingData.booking : bookingData.booking
		
		// prepare booking object
		const bookingObject = {
			reservationNumber,
			bookingInfo: booking,
			userInfo: {
				uid: user.uid,
				email: user.email,
			}
		}
		
		setLoading(true)
		handleSendDeclineReservationEmail(userObject.email, user.email.split('@')[0], bookingObject)
		.then(() => {
			removeFromReservationConflict(conflictId)
			.then(() => {
				setLoading(false)
				hideModal()
				window.location = '/'
			})
		})
	}
	
	return (
		open &&
				<Modal width={!isMobile ? '65%' : '90%'} title='Reservation Conflict' close={hideModal}>
						<ConflictModalContainer>
							{!loading && !bookingData && conflictData ?
								<div className="container">
									<div className="row">
										<div className="column">
											<div className="title">Conflicting</div>
											<div className="data_container">
												<div className="data">
													<span className="label">User:</span>
													<span className="value">{conflictData.conflictingBooking?.username}</span>
												</div>
												<div className="data">
													<span className="label">Service:</span>
													<span className="value">{conflictData.conflictingBooking?.service?.toUpperCase()}</span>
												</div>
												<div className="data">
													<span className="label">Airport:</span>
													<span className="value">{conflictData.conflictingBooking?.airport?.toUpperCase()}</span>
												</div>
												<div className="data">
													<span className="label">Date:</span>
													<span className="value">{`${moment(conflictData.conflictingBooking?.date).format('MM/DD/YYYY')}`}</span>
												</div>
												<div className="data">
													<span className="label">Flight #:</span>
													<span className="value">{`${conflictData.conflictingBooking?.flightNumber?.toUpperCase()}`}</span>
												</div>
											</div>
										</div>
										<div className="arrow">
											<ArrowForwardIcon className="arrowIcon"/>
										</div>
										<div className="column">
											<div className="title">Conflicted</div>
											<div className="data_container">
												<div className="data">
													<span className="label">Res #:</span>
													<span className="value">{`${conflictData.conflictedBooking?.bookingObject?.reservationNumber?.toUpperCase()}`}</span>
												</div>
												<div className="data">
													<span className="label">User:</span>
													<span className="value">{`${conflictData.conflictedBooking?.bookingObject?.userInfo?.email?.split('@')[0]}`}</span>
												</div>
												<div className="data">
													<span className="label">Service:</span>
													<span className="value">{`${conflictData.conflictedBooking?.bookingObject?.bookingInfo?.service?.toUpperCase()}`}</span>
												</div>
												<div className="data">
													<span className="label">Airport:</span>
													<span className="value">{`${conflictData.conflictedBooking?.bookingObject?.bookingInfo?.airport?.toUpperCase()}`}</span>
												</div>
												<div className="data">
													<span className="label">Date:</span>
													<span className="value">{`${moment(conflictData.conflictedBooking?.bookingObject?.bookingInfo?.date).format('MM/DD/YYYY')}`}</span>
												</div>
												<div className="data">
													<span className="label">Time:</span>
													<span className="value">{`${moment(conflictData.conflictedBooking?.bookingObject?.bookingInfo?.date).format('hh:mm a')}`}</span>
												</div>
												<div className="data">
													<span className="label">Flight #:</span>
													<span className="value">{`${conflictData.conflictedBooking?.bookingObject?.bookingInfo?.flightNumber?.toUpperCase()}`}</span>
												</div>
											</div>
										</div>
									</div>
									<div className="buttons">
											<Button className="button confirm_button" label="CONFIRM" color="#000000" onClick={handleConfirmReservation}/>
											<Button className="button decline_button" label="DECLINE" color="#F50014" onClick={handleDeclineReservation}/>
									</div>
								</div> :
								!loading && bookingData ?
								<div className="container">
									<div className="column">
											<div className="title">This a request for {conflictType === 'SAME-DAY' ? 'a same day' : 'Blocked Date/Time Slot'} booking</div>
											<div className="data_container" style={{ margin: '0 auto 0 auto', width: 400, maxWidth: 400 }}>
												<div className="data">
													<span className="label">User:</span>
													<span className="value">{`${bookingData.booking?.username}`}</span>
												</div>
												<div className="data">
													<span className="label">Service:</span>
													<span className="value">{`${bookingData.booking?.service?.toUpperCase()}`}</span>
												</div>
												<div className="data">
													<span className="label">Airport:</span>
													<span className="value">{`${bookingData.booking?.airport?.toUpperCase()}`}</span>
												</div>
												<div className="data">
													<span className="label">Date:</span>
													<span className="value">{`${moment(bookingData.booking?.bookingObject?.bookingInfo?.date).format('MM/DD/YYYY')}`}</span>
												</div>
												<div className="data">
													<span className="label">Time:</span>
													<span className="value">{`${moment(bookingData.booking?.bookingObject?.bookingInfo?.date).format('hh:mm a')}`}</span>
												</div>
												<div className="data">
													<span className="label">Flight #:</span>
													<span className="value">{`${bookingData.booking?.flightNumber?.toUpperCase()}`}</span>
												</div>
											</div>
										</div>
										<div className="buttons" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
											<Button className="button confirm_button" label="CONFIRM" color="#000000" onClick={handleConfirmReservation}/>
											<Button className="button decline_button" label="DECLINE" color="#F50014" onClick={handleDeclineReservation}/>
										</div>
								</div> :
								loading ? <Loader>
									<ReactLoading type='balls' color='#000000'/>
						 		</Loader> :
								<div className="not_found">
									Conflict not found!
								</div>
							}
						</ConflictModalContainer>
				</Modal>
)
}

const ConflictModalContainer = styled.div`

	.container {
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.row {
		display: flex;
		justify-content: space-evenly;
		height: 100%;
		flex: 1;

		@media (max-width: 600px) {
			flex-direction: column;
		}
	}
	
	.column {	
		height: 100%;
		flex: 1;	
		.title {
			color: var(--theme);
			font-size: 1.5rem;
		}
		.data_container {
			display: flex;
			flex-direction: column;
			
			.data {
				flex: 1;
				display: flex;
				font-size: 1.2rem;
				justify-content: space-between;
				padding: 5px 45px;

				.label {
					margin-right: 15px;
					font-weight: bold;
				}
				
				.value {
					font-weight: 500;
				}
			}

			@media (max-width: 600px) {
				width: auto !important;
			}	
		}
	}

	.arrow {
		display: flex;
		width: fit-content;
		justify-content: center;
		align-items: center;

		.arrowIcon {
			font-size: 3.5rem;
		}

		@media (max-width: 600px) {
			display: none;
		}
	}
	
	.buttons {
		padding: 15px 55px;
		width: 50%;
		display: flex;
		justify-content: space-between;
		
		.button {
			margin: 0 2.5px;
		}
		
		@media (max-width: 600px) {
			width: 100%;
			justify-content: center;
			padding: 15px 0px 15px 0px;
		}
	}

	.not_found {
		width: 100%;
		height: 200px;
		display: grid;
		place-items: center;
		font-size: 1.3rem;
		font-weight: 600;
	}
`

export default ConflictModal