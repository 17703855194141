import { Alert, Snackbar } from "@mui/material";

export const Notification = ({open, onHide, severity, title}) => (
	<Snackbar
			open={open}
			autoHideDuration={3000}
			onClose={() => onHide(false)}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
	>
			<Alert elevation={5} variant='filled' severity={severity} sx={{ width: '100%' }}>
					{title}
			</Alert>
	</Snackbar>
)